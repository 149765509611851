import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getDateToday, getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import InfoTooltip from "../../../Layout/Tooltip/InfoTooltip";
import {useTranslation} from "react-i18next";

export default function OwnconsumptionNew() {
  const navigate = useNavigate();
  const {ID} = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const [customer, setCustomer] = useState();
  const [number, setNumber] = useState();
  const [price, setPrice] = useState();
  const [isDeposit, setIsDeposit] = useState(false);
  const [date, setDate] = useState("");
  const [showDateField, setShowDateField] = useState(false);
  const [showPriceField, setShowPriceField] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const {t, i18n} = useTranslation();

  /*TODO IncomeNew / Income Edit Datum bearbeiten
  *  Darauf achten, dass bei Reserviert trotzdem "reserviert für" steht*/

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/customerAndPaymentLists/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setPaymentStatus(data.PaymentStatus);
          setCustomer(data.Customer)
          setDate(getDateToday)
          setSelectedPaymentStatus("" + data.PaymentStatus[0].ID)
          if (searchParams.get("customerID")) {
            setSelectedCustomer(searchParams.get("customerID"));
          } else {
            setSelectedCustomer("" + data.Customer[0].ID)
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      selectedUser: selectedCustomer,
      number: number,
      date: date,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/new/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("add_entry")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{i18n.t("customer")}</label>
                        <select className="form-control custom-select" id="select4" value={selectedCustomer}
                                onChange={e => setSelectedCustomer(e.target.value)} required={true}>
                          {customer.map((entry => (
                            <option key={entry.ID} value={entry.ID}>{entry.Firstname + " " + entry.Lastname}</option>
                          )))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("number")}</label>
                        <input type="number" className="form-control" id="text4"
                               placeholder={i18n.t("number_example")} onChange={e => setNumber(e.target.value)}
                               value={number || ''} required={true}/>
                      </div>
                    </div>
                    {showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("reserved_for")}</label>
                          <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)}
                                 value={date} required={true}/>
                        </div>
                      </div>
                    )}
                    {!showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("date")}</label>
                          <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)}
                                 value={date} required={true}/>
                        </div>
                      </div>
                    )}

                    <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
