import {Link} from "react-router-dom";
import {customizeDate} from "../../../../../functions/functions";
import React from "react";
import {useTranslation} from "react-i18next";

export default function OwnconsumptionListEntry({entry}) {
  const { t, i18n } = useTranslation();
  return (
    <Link to={entry.IsIncome ? "../transactions/ownconsumption/detail/" + entry.ID : "./expenses/detail/" + entry.ID} className="item">
      <div className="detail">
        <div>
          <strong>{entry.Name ? entry.Name : entry.Title}</strong>
          <p>{customizeDate(entry.Created, true)}</p>
        </div>
      </div>
      <div className="right">
        <div className={`price text-info`}>{entry.Number} {entry.Number <= 1 ? i18n.t("egg") : i18n.t("eggs")}</div>
      </div>
    </Link>
  )
}
