import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getResponseData} from "../../../../functions/functions";
import {useAuth} from "../../../../contexts/AuthContext";
import {useProject} from "../../../../contexts/ProjectContext";
import LanguageSelector from "../../../../i18n/LanguageSelector";
import {useTranslation} from "react-i18next";

export default function NewProject() {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const {currentUser} = useAuth()
  const {project, triggerProjectReload} = useProject();
  const { i18n } = useTranslation();

  function projectExists() {
    return typeof project !== 'undefined'
  }

  useEffect(() => {
    if (currentUser == null) {
      navigate("../login")
    } else {
      if (projectExists()) {
        navigate("/")
      } else {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createProject(e) {
    e.preventDefault();
    const body = {
      name: name,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/signup/createProject/`, getResponseData(body))
      .then(res => res.json())
      .then(async () => {
          await triggerProjectReload()
          navigate("../dashboard")
        }
      )
  }

  return(
    !isLoading && (
      <>
        <div className="appHeader no-border transparent position-absolute">
          <div className="left">
          <span onClick={() => navigate("../welcome")} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"/>
          </span>
          </div>
          <div className="pageTitle"/>
          <LanguageSelector doNotShowLanguageField={true} />
        </div>
        <div id="appCapsule">

          <div className="section mt-2 text-center">
            <h1>{i18n.t("new_project")}</h1>
            <h4>{i18n.t("choose_title_project")}</h4>
          </div>
          <div className="section mb-5 p-2">
            <form onSubmit={createProject}>
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("project_title")}</label>
                      <input type="text" className="form-control" id="project" placeholder="" onChange={(e) => setName(e.target.value)} value={name} required={true}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-button-group transparent">
                <button type="submit" className="btn btn-primary btn-block btn-lg">{i18n.t("create_project")}</button>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  );
}