// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA390BaF0YTOeN7SAXB8wsZzO-h8SmisLs",
  authDomain: "app.pollopi.com",
  projectId: "pollopiapp",
  storageBucket: "pollopiapp.appspot.com",
  messagingSenderId: "236529096249",
  appId: "1:236529096249:web:942d3ccf3e66eb19675fe8",
  measurementId: "G-PS8J43LDGY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);

