import {Link} from "react-router-dom";
import {customizeDate, getCurrencySignByCode} from "../../../../functions/functions";
import React from "react";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../contexts/ProjectContext";

export default function TransactionsListEntry({entry}) {
  const { t, i18n } = useTranslation();
  const {project} = useProject();
  return (
    <Link to={entry.IsIncome ? entry.PaymentStatusName === "ownconsumption" ? "../transactions/ownconsumption/detail/" + entry.ID : "../transactions/income/detail/" + entry.ID : "./expenses/detail/" + entry.ID} className="item">
      <div>
        <strong style={{color: "#27173E"}}>{entry.Name ? entry.Name : entry.Title}</strong>
        <div style={{fontSize: "11px"}} className={`text-small ${entry.PaymentStatusColor}`}>{i18n.t(entry.PaymentStatusName)}</div>
      </div>
      <div className="text-end">
        <div style={{fontWeight: "500"}} className={`price ${entry.IsIncome ? ( entry.PaymentStatusColor ? entry.PaymentStatusColor : "text-success" ) : "text-danger" }`}>{entry.Price} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</div>
        <p>{customizeDate(entry.Created, true)}</p>
      </div>
    </Link>
  )
}
