import React from "react"
import {Navigate, Outlet, useLocation} from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"

export default function PublicRoutes({children}) {
  const { currentUser } = useAuth();
  let location = useLocation();

  if (currentUser) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
}
