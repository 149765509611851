import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getCurrencyList, getResponseData, numberToEnglishDecimal} from "../../../../../functions/functions";
import {useProject} from "../../../../../contexts/ProjectContext";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";
import {currency_list} from "../../../../../currencies/currencies";

export default function ProjectEdit()
{
  const navigate = useNavigate();
  const { ID } = useParams();
  const {currentUser} = useAuth()
  const {triggerProjectReload} = useProject()
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState();
  const [eggsStatisticsSeed, setEggsStatisticsSeed] = useState(0);
  const [cashBalanceSeed, setCashBalanceSeed] = useState(0);
  const [disableReserved, setDisableReserved] = useState(false);
  const [disableTransactions, setDisableTransactions] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/project/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setName(data.Project.Title)
          setSelectedCurrency(data.Project.CurrencyCode)
          setDisableReserved(data.Project.DisableReserved)
          setDisableTransactions(data.Project.DisableTransactions)
          setEggsStatisticsSeed(data.Project.EggsStatisticsSeed)
          setCashBalanceSeed(numberToEnglishDecimal(data.Project.CashBalanceSeed))
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      id: ID,
      name: name,
      currency: selectedCurrency,
      DisableReserved: disableReserved,
      DisableTransactions: disableTransactions,
      eggsStatisticsSeed: eggsStatisticsSeed,
      cashBalanceSeed: cashBalanceSeed,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/project/edit/`, getResponseData(body))
      .then(res => res.json())
      .then(
        async (result) => {
          await triggerProjectReload();
          navigate("../settings", {reload: 'true'});
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  function handleInput(e) {
    setName(e.target.value);
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("edit_project")} showBackButton={true} />
          <div id="appCapsule">
            <form onSubmit={handleSubmit}>
            <div className="section mt-2 mb-2">
              <div className="section-title">{i18n.t("project")}</div>
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("name")}</label>
                      <input type="text" className="form-control" id="text4" placeholder="" onChange={handleInput} value={name} required={true}/>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="select4">{i18n.t("currency")}</label>
                      <select className="form-control custom-select" id="select4" value={selectedCurrency}
                              onChange={e => setSelectedCurrency(e.target.value)} required={true}>
                        {getCurrencyList().map((entry => (
                          <option key={entry.code} value={entry.code}>{entry.name} ( {entry.symbol} )</option>
                        )))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section mt-2 mb-2">
              <div className="section-title">{i18n.t("starting_values")}</div>
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("egg_stock")}</label>
                      <input type="number" className="form-control" id="text4" placeholder="" onChange={(e) => setEggsStatisticsSeed(e.target.value)} value={eggsStatisticsSeed} />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("cash_balance_in")} €</label>
                      <input type="number" className="form-control" id="text4" placeholder="12,00" onChange={(e) => setCashBalanceSeed(numberToEnglishDecimal(e.target.value))} value={cashBalanceSeed} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section mt-2 mb-2">
              <div className="section-title">{i18n.t("functions")}</div>
              <div className="card">

                <ul className="listview simple-listview transparent flush">
                  <li>
                    <div>{i18n.t("deactivate_transactions")}</div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="SwitchCheckDefault4" defaultChecked={disableTransactions} onChange={(e) => setDisableTransactions(e.target.checked)} />
                      <label className="form-check-label" htmlFor="SwitchCheckDefault4"></label>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
            <div className="section mt-2 mb-2">
              <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
            </div>
            </form>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}
