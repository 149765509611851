import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import {useTranslation} from "react-i18next";

export default function Layout() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Outlet />
      <Navbar />
      <div id="toast" className="toast-box toast-top">
        <div className="in">
          <div className="text">

          </div>
        </div>
      </div>
    </>
  );
}
