import React from "react";
import {customizeDate, getCurrencySignByCode} from "../../../../../functions/functions";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../../contexts/ProjectContext";

export default function ExpensesList({title, list}) {
  const { t, i18n } = useTranslation();
  const {project} = useProject();
  return (
    <div className="section mt-2">
      <div className="section-title">{title}</div>
      <div className="card">
        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
          {list.map((entry => (
            <li key={entry.ID}>
              <Link to={"./detail/" + entry.ID} className="item">
                <div className="in">
                  <div>
                    <strong>{entry.Title}</strong>
                    <div className="text-small text-secondary">{entry.PaymentStatus}</div>
                  </div>
                  <div className="text-end">
                    <strong>{entry.Price} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</strong>
                    <div className="text-small">
                      {customizeDate(entry.Created, true)}
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          )))}
        </ul>
      </div>
    </div>
  );
}
