import React, {useEffect, useState} from "react";
import {customizeDate, getResponseData} from "../../../../../functions/functions";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function OwnconsumptionDetail() {
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const {ID} = useParams();
  const [incomeEntry, setIncomeEntry] = useState([]);
  const [showUserDeleted, setShowUserDeleted] = useState(true);
  const [customerName, setCustomerName] = useState();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/detail`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const res = JSON.parse(result);
          const data = res.IncomeEntry
          setIncomeEntry(data);
          setCustomerName(data.Customer)
          let help = 0;
          data.CustomerList.forEach(customer => {
            if (customer.ID === data.CustomerID) {
              help += 1;
            }
          })
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function deleteEntry() {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/incomes/delete`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("details")} showBackButton={true} showDetailHeader={true} dataBsTarget={"deleteItem"}
                  editNavigateTo={"../transactions/ownconsumption/edit/" + incomeEntry.ID}/>
          <div id="appCapsule" className="full-height bg-white">
            <div className="section mt-2 mb-2">

              <div className="listed-detail mt-3">
                <div className="icon-wrapper">
                  <div className="iconbox">
                    <ion-icon name="egg"></ion-icon>
                  </div>
                </div>
                <h3 className="text-center mt-2">
                  {i18n.t("ownconsumption")}
                </h3>
              </div>

              <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                  <strong>{i18n.t("name")}</strong>
                  <span>{incomeEntry.Customer}</span>
                </li>
                <li>
                  <strong>{i18n.t("number")}</strong>
                  <span>{incomeEntry.Number}</span>
                </li>
                <li>
                  <strong>{i18n.t("status")}</strong>
                  <span className={`${incomeEntry.PaymentStatusColor}`}>{i18n.t(incomeEntry.PaymentStatusName)}</span>
                </li>
                <li>
                  <strong>{i18n.t("date")}</strong>
                  <span>{customizeDate(incomeEntry.Created, true)}</span>
                </li>
              </ul>


            </div>
            <LegalFooter/>
          </div>
          <div className="modal fade dialogbox" id="deleteItem" data-bs-backdrop="static" tabIndex="-1"
               style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{i18n.t("delete_entry")}</h5>
                </div>
                <div className="modal-body mb-2">
                  {i18n.t("are_you_sure")}
                </div>
                <div className="modal-footer">
                  <div className="btn-inline">
                    <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal">{i18n.t("quit")}</a>
                    <span onClick={deleteEntry} className="btn btn-text-danger"
                          data-bs-dismiss="modal">{i18n.t("delete")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
