import React from "react";
import {useTranslation} from "react-i18next";

export default function HenhouseTab() {
  const { t, i18n } = useTranslation();
  return (
    <div className="tab-pane fade show" id="henhouse" role="tabpanel">
      <div className="alert alert-danger mb-2" role="alert">
        {i18n.t("beta_functions")}
      </div>
      <div className="row">
        <div className="col-6">
          <div className="stat-box">
            <div className="title">{i18n.t("temperature")}</div>
            <div className="value text-success">{12} °C</div>
          </div>
        </div>
        <div className="col-6">
          <div className="stat-box">
            <div className="title">{i18n.t("humidity")}</div>
            <div className="value text-warning">{22} %</div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <div className="card-block mb-2 bg-white">
            <div className="card-main" style={{backgroundImage: "none"}}>
              <div className="balance">
                <h1 className="btn-text-success" style={{textAlign: "center", fontSize: "50px"}}><ion-icon name="power"/></h1>
                <h2 className="title" style={{fontSize: "25px"}}>{i18n.t("open_door")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section inset mt-2 p-0">
        <div className="section-title">{i18n.t("automatic_control")}</div>


        <div className="accordion" id="accordionExample1">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion01">
                {i18n.t("set_opening_and_closing_time")}
              </button>
            </h2>
            <div id="accordion01" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
              <div className="accordion-body">
                <form>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="userid1">{i18n.t("opening_time")}</label>
                      <input type="text" className="form-control" id="userid1" placeholder="08:30" />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="amount1">{i18n.t("closing_time")}</label>
                      <input type="text" className="form-control" id="amount1" placeholder="19:30" />
                    </div>
                  </div>
                  <button type="button" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
