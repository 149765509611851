import {getLanguages} from "../i18n/i18n";
import {currency_list} from "../currencies/currencies";

export function customizeDate(SilverStripeDate, showYear = null) {
  let date = SilverStripeDate.split(' ')[0].split('-');
  let day = date[2];
  let month = date[1];
  let year = date[0];

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  if (showYear) {
    return day + "." + month + "." + year;
  } else {
    return day + "." + month + ".";
  }
}

export function customizeDateForComparisons(SilverStripeDate) {
  let date = SilverStripeDate.split(' ')[0].split('-');
  let day = date[2];
  let month = date[1];
  let year = date[0];

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return year + "" + month + "" + day;
}

export function isNumber(n) {
  n = n.replace(/\./g, '').replace(',', '.');
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function numberToEnglishDecimal(n) {
  return n.replace(/,/g, '.')
}

export function getResponseData(body = {}) {
  return {
    method: 'POST',
    body: JSON.stringify(body)
  };
}

export async function projectExists(uid) {
  const body = {
    uid: uid,
  }
  const res = await fetch(`https://api.pollopi.com/settings/project/detail/`, getResponseData(body))
  const data = JSON.parse(await res.json())
  return "Project" in data;
}

export function post(path, params, method='post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function getLocalizedName(lng) {
  return (
    getLanguages().map((l => (
        l.language === lng ? l.localizedName : ""
      )))
  )
}

export function getDateToday() {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return year + "-" + month + "-" + day;
}

export function getCurrencyList() {
  return currency_list;
}

export function getCurrencySignByCode(code) {
  const list = getCurrencyList();
  let symbol = "";
  list.forEach(element => {
    if (element.code === code) {
      symbol = element.symbol;
    }
  });
  return symbol;
}