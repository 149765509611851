import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {customizeDate} from "../../../../functions/functions";
import {useTranslation} from "react-i18next";

export default function StatisticsList({entries})
{
  const navigate = useNavigate();
  const [sliceValue, setSliceValue] = useState(7);
  const [showButton, setShowButton] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (entries.length > 7) {
      setShowButton(true);
    }
  }, [])

  function changeListViewShowAll() {
    setSliceValue(entries.length);
    setShowButton(false);
  }

  function changeListView() {
    setSliceValue(sliceValue+7);
    if (sliceValue+7>entries.length) {
      setShowButton(false);
    }
  }

  function navigateToEditPage(ID) {
    navigate("../statistics/edit/" + ID);
  }

  return(
    <div className="section mt-4 mb-3">
      <div className="section-heading">
        <h2 className="title">{i18n.t("statistics")}</h2>
        <span onClick={changeListViewShowAll} className="link">{i18n.t("show_all")}</span>
      </div>
      <div className="card">
        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
          <li style={{borderBottom: "solid 1px rgba(0, 0, 0, 0.20)"}}>
            <div className="item">
              <div className="in">
                <div>
                  <strong>{i18n.t("date")}</strong>
                </div>
                <div className="text-end" style={{}}>
                  <strong>{i18n.t("number")}</strong>
                </div>
                <div></div>
              </div>
            </div>
          </li>
          {entries.slice(0,sliceValue).map((entry => (
            <li key={entry.ID} className={"statisticsList"} onClick={() => navigateToEditPage(entry.ID)}>
              <div className="item">
                <div className="in">
                  <div>
                    <strong>{customizeDate(entry.Created)}</strong>
                  </div>
                  <div className="text-end" style={{}}>
                    <strong>{parseInt(entry.number)}</strong>
                  </div>
                  <div> ></div>
                </div>
              </div>
            </li>
          )))}
        </ul>
      </div>
      {showButton && (<div className="pt-2">
        <span onClick={changeListView} className="btn btn-block btn-outline-secondary">{i18n.t("more")}</span>
      </div>)}
      <div className="content-header mt-2" style={{color: "#958d9e"}}>
        <p style={{fontSize: "12px"}}>{i18n.t("statistics_notice")}</p>
      </div>
    </div>
  );
}
