import React, {useEffect, useState} from "react";
import {customizeDate, getCurrencySignByCode, getResponseData} from "../../../../../functions/functions";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../../contexts/ProjectContext";

export default function ExpenseDetail() {
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const {ID} = useParams();
  const [expenseEntry, setExpenseEntry] = useState([]);
  const { t, i18n } = useTranslation();
  const {project} = useProject();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/expenses/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setExpenseEntry(data.ExpenseEntry);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function deleteEntry() {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    const responseData = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    fetch(`https://api.pollopi.com/transactions/expenses/delete/`, responseData)
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("details")} showBackButton={true} showDetailHeader={true} dataBsTarget={"deleteItem"}
                  editNavigateTo={"../transactions/expenses/edit/" + expenseEntry.ID}/>
          <div id="appCapsule" className="full-height bg-white">
            <div className="section mt-2 mb-2">

              <div className="listed-detail mt-3">
                <div className="icon-wrapper">
                  <div className="iconbox bg-danger">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                  </div>
                </div>
                <h3 className="text-center mt-2">
                  {i18n.t("expense")}
                </h3>
              </div>

              <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                  <strong>{i18n.t("title")}</strong>
                  <span>{expenseEntry.Title}</span>
                </li>
                <li>
                  <strong>{i18n.t("price")}</strong>
                  <span>{expenseEntry.Price} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</span>
                </li>
                <li>
                  <strong>{i18n.t("note")}</strong>
                  <span style={{whiteSpace: "pre-line", lineHeight: "1.5"}}>{expenseEntry.Notice}</span>
                </li>
                <li>
                  <strong>{i18n.t("date")}</strong>
                  <span>{customizeDate(expenseEntry.Created, true)}</span>
                </li>
              </ul>


            </div>
          </div>
          <div className="modal fade dialogbox" id="deleteItem" data-bs-backdrop="static" tabIndex="-1"
               style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{i18n.t("delete_entry")}</h5>
                </div>
                <div className="modal-body mb-2">
                  {i18n.t("are_you_sure")}
                </div>
                <div className="modal-footer">
                  <div className="btn-inline">
                    <a href="#" className="btn btn-text-secondary"
                       data-bs-dismiss="modal">{i18n.t("quit")}</a>
                    <span onClick={deleteEntry} className="btn btn-text-danger"
                          data-bs-dismiss="modal">{i18n.t("delete")}</span>
                  </div>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
