import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import {verifyPasswordResetCode, confirmPasswordReset} from "firebase/auth";
import {auth} from "../../../config/firebase"

export default class ResetPassword extends Component {
  state = {
    email: null,
    error: '',
    password: '',
    success: false,
    validCode: null,
    verifiedCode: false,
  }

  componentDidMount() {
    console.log(this.props.actionCode);
    verifyPasswordResetCode(auth, this.props.actionCode).then((email) => {
      this.setState({ email, validCode: true, verifiedCode: true });
    }).catch((error) => {
      console.log(error.errors)
      this.setState({ error: error.message, validCode: false, verifiedCode: true });
    });
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    const { actionCode } = this.props;
    const newPassword = this.state.password;

    confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
      this.setState({ success: true });
    }).catch((error) => {
      this.setState({ error: error.message });
    });
  }

  setText = (evt) => {
    this.setState({ password: evt.target.value });
  }

  render() {
    const {
      email,
      error,
      password,
      success,
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <>NICHTS</>;
    } else if (success) {
      component = (
        <div className="ResetPassword">
          <h1>Password changed</h1>
          <p>You can now sign in with your new password</p>
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div className="ResetPassword">
          <h1>Reset your password</h1>
          <div>for <span>{email}</span></div>

          <form onSubmit={this.handleResetPassword}>

            {error ? <div className="error">{error}</div> : ''}

            <input
              onChange={this.setText}
              value={password}
              type="password"
              placeholder="New password"
              required
            />
            <input
              type="submit"
              value="SAVE"
            />
          </form>
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="ResetPassword">
          <h1>Try resetting your password again</h1>
          <p className="error">{error}</p>
        </div>
      );
    }

    return component;
  }
}
