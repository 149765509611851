import i18next from "i18next";
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalazyMeta from '../localazy-meta';
import enJson from "../locales/en.json";
import deJson from "../locales/de.json";
import frJson from "../locales/fr.json";
import plJson from "../locales/pl.json";
import esJson from "../locales/es.json";

export const i18n = i18next;

export const getSupportedLangCodes = () => {
  return LocalazyMeta.languages.map(l => l.language)
}

export const getBaseLanguage = () => {
  return LocalazyMeta.baseLocale;
}

export const getCurrentLanguage = () => {
  return window.localStorage.i18nextLng || 'en';
}

export const getLanguages = ()=>{
  return LocalazyMeta.languages;
}

export const initI18n = (callback) => {
  i18next.init({

  }, function (err, t) {
    callback()
  });
}



i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: window.navigator.language.substring(0,2),
    fallbackLng: getBaseLanguage(),
    debug: false,
    supportedLngs: getSupportedLangCodes(),
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
    resources: {
      en: {
        translation: enJson,
      },
      de: {
        translation: deJson,
      },
      fr: {
        translation: frJson,
      },
      pl: {
        translation: plJson,
      },
      es: {
        translation: esJson,
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;