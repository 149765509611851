//https://www.html-code-generator.com/javascript/json/currency-name
export var currency_list = [
  {"name":"Dollar","code":"AUD","symbol":"$"},
  {"name":"British Pound","code":"GBP","symbol":"£"},
  {"name":"Chinese Yuan","code":"CNY","symbol":"¥"},
  {"name":"Krone","code":"DKK","symbol":"Kr"},
  {"name":"Euro","code":"EUR","symbol":"€"},
  {"name":"Polish Zloty","code":"PLN","symbol":"zł"},
  {"name":"Swiss Franc","code":"CHF","symbol":"CHf"},
  {"name":"Turkish Lira","code":"TRY","symbol":"₺"}
];