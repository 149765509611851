import React, {useEffect, useRef, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {getCurrencySignByCode, getResponseData, numberToEnglishDecimal} from "../../../../../functions/functions";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import Loader from "../../../Layout/Loader/Loader";
import InfoTooltip from "../../../Layout/Tooltip/InfoTooltip";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../../contexts/ProjectContext";

export default function IncomeEdit()
{
  const navigate = useNavigate();
  const { ID } = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const [customer, setCustomer] = useState();
  const [customerName, setCustomerName] = useState();
  const [number, setNumber] = useState();
  const [notice, setNotice] = useState("");
  const [price, setPrice] = useState(0.00);
  const [isDeposit, setIsDeposit] = useState(false);
  const [date, setDate] = useState("0");
  const [showDateField, setShowDateField] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  const [showUserDeleted, setShowUserDeleted] = useState(true);
  const { t, i18n } = useTranslation();
  const {project} = useProject();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/incomes/detail`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const raw = JSON.parse(result);
          const data = raw.IncomeEntry;
          setPaymentStatus(data.PaymentStatusList);
          setCustomer(data.CustomerList);
          setCustomerName(data.Customer);
          setSelectedPaymentStatus(data.PaymentStatusID);
          setDate(data.Created.split(' ')[0]);
          if (data.PaymentStatusID === 4) {
            setShowDateField(true);
          }
          let help = 0;
          data.CustomerList.forEach(customer => {
            if (customer.ID === data.CustomerID) {
              help += 1;
            }
          })
          if (help) {
            setShowUserDeleted(false)
          }
          setSelectedCustomer(data.CustomerID)
          setNumber(data.Number)
          setNotice(data.Notice)
          setPrice(numberToEnglishDecimal(data.Price))
          setIsDeposit(data.IsDeposit)
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      id: ID,
      selectedCustomer: selectedCustomer,
      number: number,
      selectedPaymentStatus: selectedPaymentStatus,
      price: price,
      isDeposit: isDeposit,
      date: date,
      notice: notice,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/incomes/edit`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate("../transactions");
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  function handleIsDeposit(e) {
    setIsDeposit(e.target.checked)
    setShowDateField(false)
    setNumber(0);
    setSelectedPaymentStatus(5);
  }

  function handlePrice(e) {
    setPrice(numberToEnglishDecimal(e.target.value))
  }

  function handlePaymentStatus(e) {
    setSelectedPaymentStatus(e.target.value)
    if (e.target.value == 4) {
      setShowDateField(true);
    } else {
      setShowDateField(false);
    }
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("edit_entry")} showBackButton={true} />
          <div id="appCapsule">
            {showUserDeleted && (
              <div className="section mt-2 mb-2">
                <div className="alert alert-danger mb-1" role="alert">
                  {customerName} - {i18n.t("customer_already_deleted")}
                </div>
              </div>
            )}

            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                  {!showUserDeleted && (
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{i18n.t("customer")}</label>
                        <select className="form-control custom-select" id="select4" value={selectedCustomer} onChange={e => setSelectedCustomer(e.target.value)} required={true}>
                          {customer.map((entry => (
                            <option key={entry.ID} value={entry.ID}>{entry.Name}</option>
                          )))}
                        </select>
                      </div>
                    </div>
                  )}
                  {!isDeposit && (
                    <>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("number")}</label>
                          <input type="number" className="form-control" id="text4" placeholder={i18n.t("number_example")} onChange={e => setNumber(e.target.value)} value={number  || ''} required={true}/>
                        </div>
                      </div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="select4">{i18n.t("payment_status")}</label>
                          <select className="form-control custom-select" id="select4" value={selectedPaymentStatus} onChange={handlePaymentStatus} required={true}>
                            {paymentStatus.map((entry => (
                              <option key={entry.ID} value={entry.ID}>{i18n.t(entry.translatableName)}</option>
                            )))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("price_in")} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</label>
                      <input type="number" className="form-control" id="text4" placeholder={i18n.t("price_example")} onChange={handlePrice} value={price} step="0.01" required={true}/>
                    </div>
                  </div>
                  {showDateField && (
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("reserved_for")}</label>
                        <input type="date" className="form-control" id="date" placeholder="z.B. 3,00" onChange={e => setDate(e.target.value)} value={date} required={true}/>
                      </div>
                    </div>
                  )}
                  {!showDateField && (
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("date")}</label>
                        <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)} value={date} required={true}/>
                      </div>
                    </div>
                  )}
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="textarea4">{i18n.t("note")}</label>
                        <textarea id="expanses_note" rows="5" className="form-control"
                                  placeholder={i18n.t("note_example_income")} value={notice}
                                  onChange={e => setNotice(e.target.value)}/>
                        <i className="clear-input">
                          <ion-icon name="close-circle" role="img" className="md hydrated"
                                    aria-label="close circle"/>
                        </i>
                      </div>
                    </div>
                  <ul className="listview simple-listview transparent flush">
                    <li style={{padding: "0"}}>
                      <div>{i18n.t("deposit")}</div>
                      <InfoTooltip text={i18n.t("deposit_tooltip")} fontsize={"20px"} fontcolor={"#4A89DC"} />
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="SwitchCheckDefault3" defaultChecked={isDeposit} onChange={handleIsDeposit} />
                        <label className="form-check-label" htmlFor="SwitchCheckDefault3"/>
                      </div>
                    </li>
                  </ul>

                  <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}
