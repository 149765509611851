import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {useAuth} from "../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {customizeDate, getResponseData} from "../../../../functions/functions";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import Loader from "../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function StatisticsEdit() {
  const navigate = useNavigate();
  const {ID} = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState();
  const [number, setNumber] = useState("");
  const [broken, setBroken] = useState("");
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/statistics/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          let number = data.Values.number > 0 ? data.Values.number : "";
          setNumber(number);
          setBroken(data.Values.broken)
          setDate(customizeDate(data.Values.Created, true));
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      id: ID,
      number: number,
      broken: broken,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/statistics/edit/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("edit_entry")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="section-title">{i18n.t("date")}: {date}</div>
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("number")}</label>
                        <input type="number" className="form-control" id="number" placeholder={i18n.t("number_example")}
                               onChange={(e) => {
                                 setNumber(e.target.value)
                                 if (e.target.value.length <= 0) {
                                   setBroken("0")
                                 }
                                 if (parseInt(e.target.value) <= broken) {
                                   setBroken(e.target.value);
                                 }
                               }} value={number} required={true}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("broken")}</label>
                        <input type="number" className="form-control" id="number" disabled={parseInt(number) < 1 || number === ""} placeholder={i18n.t("number_example")}
                               onChange={(e) => {
                                 if (parseInt(e.target.value) >= parseInt(number)) {
                                   setBroken(number)
                                 } else {
                                   setBroken(e.target.value)
                                 }
                               }} value={broken} required={true}/>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
              <div className="content-header mt-2" style={{color: "rgb(149, 141, 158)"}}>
                <p style={{fontSize: "12px"}}>
                  {i18n.t("broken_eggs_note")}
                </p>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
