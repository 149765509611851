import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ChartElement({chartData}) {
  const { t, i18n } = useTranslation();
  const options = {
    chart: {
      type: 'area',
      width: '100%',
      height: 140,
      sparkline: {
        enabled: true
      },
    },
    yaxis: {
      min: 0
    },

    stroke: {
      width: 2,
    },
    colors: ['#8CC152'],
    tooltip: {
      enabled: false
    }
  }

  const [chartArray, setChartArray] = useState([]);

  useEffect(() => {
    setChartArray(chartData.Chart7D);
  }, []);

  /*TODO Language for Chart-Element*/

  return (
    <div className="section mb-2 mt-2">
      <div className="card">
        <div className="card-body pt-1">
          <ul className="nav nav-tabs lined" role="tablist">
            <li className="nav-item">
              <Link className="nav-link active" data-bs-toggle="tab" to={"#tab-1w"} onClick={() => setChartArray(chartData.Chart7D)} role="tab">
                {i18n.t("one_week")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to={"#tab-1w"} onClick={() => setChartArray(chartData.Chart1M)} role="tab">
                {i18n.t("one_month")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to={"#tab-1w"} onClick={() => setChartArray(chartData.Chart3M)} role="tab">
                {i18n.t("three_months")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to={"#tab-1w"} onClick={() => setChartArray(chartData.Chart6M)} role="tab">
                {i18n.t("six_months")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to={"#tab-1w"} onClick={() => setChartArray(chartData.ChartAll)} role="tab">
                {i18n.t("all")}
              </Link>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="tab-1w" role="tabpanel">
            <Chart
              options={options}
              series={chartArray}
              height="140"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
