import React from "react"
import ReactDOM from "react-dom"
import App from "./components/App/App"
import './i18n/i18n';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>, document.getElementById("root"));
