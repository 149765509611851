import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../Layout/Loader/Loader";
import {getResponseData} from "../../../../functions/functions";
import {useTranslation} from "react-i18next";

export default function CollectionPage(ID) {
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      ID: ID
    }

    fetch(`https://api.pollopi.com/intercom/collection`, getResponseData(body))
      .then(res => res.json())
      .then(async (result) => {
        const data = await JSON.parse(result).Collections;
        setCollections(data);
        console.log(data)
        setIsLoading(false);
      }, (error) => {
        setIsLoading(true);
      })
  }, [])

  return (<>
    <Loader isLoading={isLoading}/>
    {!isLoading && (<>
      <Header title={"Hilfe"}/>
      <div id="appCapsule">

        <div className="section mt-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Sammlungen</h5>
              <p className="card-text">
                Klicke auf die passende Sammlung für deine Problematik.
              </p>
            </div>
          </div>
        </div>

        <ul className="listview link-listview inset mt-2">
          {collections.length > 0 ? (
            collections.map((collection) => (
              <li key={collection.id}>
                <Link to={"../help/collection/" + collection.id} className="item">
                  <div className="in">
                    <div>{collection.name}</div>
                  </div>
                </Link>
              </li>
            ))) : ""}
        </ul>

      </div>
    </>)}
  </>);
}
