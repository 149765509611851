import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import TransactionsList from "../TransactionsList";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getCurrencySignByCode, getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function CustomerDetail() {
  const [isLoading, setIsLoading] = useState(true);
  const [incomeList, setIncomeList] = useState();
  const [incomeTotal, setIncomeTotal] = useState();
  const [customer, setCustomer] = useState();
  const [eggsSold, setEggsSold] = useState();
  const [eggsReserved, setEggsReserved] = useState();
  const {currentUser} = useAuth()
  const { ID } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/customers/detail`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setCustomer(data.Customer);
          setIncomeList(data.IncomeList);
          setIncomeTotal(data.IncomeBalance);
          setEggsSold(data.EggsSold);
          setEggsReserved(data.EggsReserved);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleClick(destination) {
    navigate(destination);
  }

  function deleteEntry() {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/customers/delete`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        }
      )
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("customer")} showBackButton={true} showDetailHeader={true} dataBsTarget={"deleteItem"} editNavigateTo={"../transactions/customer/edit/" + ID} />
          <div id="appCapsule">
            <div className="section wallet-card-section pt-2">
              <div className="wallet-card">
                <div className="balance mb-0">
                  <div className="left"><span className="title">{customer.Name}</span><h1 className="total text-success">{incomeTotal} €</h1></div>
                  <div className="right"><a href="#" className="button" data-bs-toggle="modal"
                                            data-bs-target="#DialogBlockButton">
                    <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon>
                  </a></div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="row mt-2">
                <div className="col-6">
                  <div className="stat-box">
                    <div className="title">{i18n.t("eggs_sold")}</div>
                    <div className="value text-success" style={{"fontSize" : "22px"}}>{eggsSold}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="stat-box">
                    <div className="title">{i18n.t("eggs_reserved")}</div>
                    <div className="value text-info" style={{fontSize: "22px"}}>{eggsReserved}</div>
                  </div>
                </div>
              </div>
            </div>
            <TransactionsList entries={incomeList} />
            <LegalFooter />
          </div>
        </>
      )}
      <div className="modal fade dialogbox" id="DialogBlockButton" data-bs-backdrop="static" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-footer">
              <div className="btn-list">
                <span onClick={() => handleClick("../transactions/income/new?customerID=" + ID)} className="btn btn-text-primary btn-block" data-bs-dismiss="modal">{i18n.t("add_income")}</span>
                <a href="#" className="btn btn-text-secondary btn-block" data-bs-dismiss="modal">{i18n.t("quit")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade dialogbox" id="deleteItem" data-bs-backdrop="static" tabIndex="-1" style={{display: "none"}} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{i18n.t("delete_customer")}</h5>
            </div>
            <div className="modal-body mb-2">
              {i18n.t("are_you_sure")}
            </div>
            <div className="modal-footer">
              <div className="btn-inline">
                <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal">{i18n.t("quit")}</a>
                <span onClick={deleteEntry} className="btn btn-text-danger" data-bs-dismiss="modal">{i18n.t("delete")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
