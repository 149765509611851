import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {customizeDate, getCurrencyList, getResponseData} from "../../../../functions/functions";
import Loader from "../../Layout/Loader/Loader";
import {useAuth} from "../../../../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {useProject} from "../../../../contexts/ProjectContext";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";

export default function StockPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [stock, setStock] = useState(true);
  const [entries, setEntries] = useState(true);
  const {currentUser} = useAuth();
  const {project} = useProject();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['sortType']);
  const [sortType, setSortType] = useState(1);

  function handleClick(destination) {
    navigate(destination);
  }

  function getTotalOfChicken(hens, rooster) {
    if (hens == null && rooster == null) {
      return 0;
    }
    if (hens == null) {
      return parseInt(rooster);
    }
    if (rooster == null) {
      return parseInt(hens);
    }
    return parseInt(rooster) + parseInt(hens);
  }

  /*
  1 => Name
  2 => Ringfarbe
  3 => Tag der Einstallung
  4 => Anzahl der Tiere
   */
  function sortEntries(entries, type) {

    if (parseInt(type) === 1) {
      entries.sort(function(a, b){
        let x = a.ChickenBreed.toLowerCase();
        let y = b.ChickenBreed.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }

    if (parseInt(type) === 2) {
      entries.sort(function(a, b){
        let x = b.RingColor.toLowerCase();
        let y = a.RingColor.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }

    if (parseInt(type) === 3) {
      entries.sort(function(a, b){
        let x = b.Created;
        let y = a.Created;
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }

    if (parseInt(type) === 4) {
      entries.sort(function(a, b){
        let y = getTotalOfChicken(a.NumberOfHens, a.NumberOfRooster);
        let x = getTotalOfChicken(b.NumberOfHens, b.NumberOfRooster);
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }

    return entries;
  }

  function triggerSortList(e) {
    setCookie('sortType', e.target.value, {path: '/'})
    setSortType(e.target.value);
    setEntries(sortEntries(entries, e.target.value))
  }

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/stock/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setStock(data.Stock);
          if (cookies.sortType) {
            setSortType(cookies.sortType);
            setEntries(sortEntries(data.Stock.Entries, cookies.sortType))
          } else {
            setCookie('sortType', sortType, {path: '/'})
            setEntries(sortEntries(data.Stock.Entries, sortType))
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("stock")}/>
          <div id="appCapsule">
            <div className="section wallet-card-section pt-2">
              <div className="wallet-card">
                <div className="balance mb-0">
                  <div className="left"><span className="title">{project.Title} - {i18n.t("stock")}</span><h1
                    className="total text-success">{stock.Number} 🐔</h1></div>
                  <div className="right"><a href="#" className="button" data-bs-toggle="modal"
                                            data-bs-target="#DialogBlockButton">
                    <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon>
                  </a></div>
                </div>
              </div>
            </div>

            <div className="section mt-4">
              <div className="section-heading">
                <h2 className="title">{i18n.t("chicken_breeds")}</h2>
                <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PanelRight">{i18n.t("sort")}&nbsp;<ion-icon style={{fontSize: "18px !important", marginRight: 0}} name="filter-circle-outline"/></a>
              </div>
              <div className="transactions mb-3">
                {entries.length > 0 ? (
                  entries.map(entry => (
                    <Link key={entry.ID} to={"../stock/entry/detail/" + entry.ID} className="item">
                      <div className="detail">
                        <div>
                          <strong>{entry.ChickenBreed}</strong>
                          {(parseInt(sortType) === 4 || parseInt(sortType) === 3 || parseInt(sortType) === 2 || parseInt(sortType) === 1) && (
                            <p>
                              {parseInt(entry.NumberOfHens) > 0 ? (
                                <>
                                  {entry.NumberOfHens} {i18n.t("hens")}
                                </>
                              ) : ""}
                              {parseInt(entry.NumberOfRooster) > 0 ? (
                                <>
                                  {entry.NumberOfHens > 0 ? " - " : ""}{entry.NumberOfRooster} {i18n.t("rooster")}
                                </>
                              ) : ""}
                            </p>
                          )}
                          {(parseInt(sortType) === 4 || parseInt(sortType) === 3 || parseInt(sortType) === 2 || parseInt(sortType) === 1) && (
                            <p>
                              {entry.RingColor === "" ? "" : i18n.t("ring_color") + ": " + entry.RingColor}
                            </p>
                          )}
                          {(parseInt(sortType) === 4 || parseInt(sortType) === 3 || parseInt(sortType) === 2 || parseInt(sortType) === 1) && (
                            <p>
                              {i18n.t("day_of_stabling") + ": " + customizeDate(entry.Created, true)}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="right">
                        <div
                          className="price text-info">{getTotalOfChicken(entry.NumberOfHens, entry.NumberOfRooster)} {i18n.t("chicken")}
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (i18n.t("no_entries_yet"))}
              </div>
            </div>

            <div className="modal fade panelbox panelbox-right" id="PanelRight" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{i18n.t("sort_by")}</h5>
                    <a href="#" data-bs-dismiss="modal">{i18n.t("close")}</a>
                  </div>
                  <div className="modal-body">
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{i18n.t("sort_by")}</label>
                        <select className="form-control custom-select" id="select4" required={true} value={sortType} onChange={triggerSortList}>
                          <option key={1} value={1}>{i18n.t("sort_by_name")}</option>
                          <option key={2} value={2}>{i18n.t("sort_by_ring_color")}</option>
                          <option key={3} value={3}>{i18n.t("sort_by_day_of_stabling")}</option>
                          <option key={4} value={4}>{i18n.t("sort_by_number_of_chickens")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
      Modal Section
    */}
          <div className="modal fade dialogbox" id="DialogBlockButton" data-bs-backdrop="static" tabIndex="-1"
               role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-footer">
                  <div className="btn-list">
                    <span onClick={() => handleClick("../stock/entry/new")} className="btn btn-text-primary btn-block"
                          data-bs-dismiss="modal">{i18n.t("stabling")}</span>
                    <a href="#" className="btn btn-text-secondary btn-block"
                       data-bs-dismiss="modal">{i18n.t("quit")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}