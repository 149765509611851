import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import CustomerList from "./CustomerList";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function CustomerPage() {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/customers/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setCustomerList(data.CustomerList);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("customer")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="mb-3">
              {customerList.length > 0 ? (
                customerList.map((item) => (
                  <CustomerList key={item.letter} title={item.letter} list={item.list}/>
                ))
              ) : (
                <div className="section mt-2">
                  <p>{i18n.t("no_customer_yet")}</p>
                  <p><Link to={"../transactions/customer/new"}>{i18n.t("add_customer_now")}</Link></p>
                </div>
              )}
            </div>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}
