import React, {useEffect, useRef, useState} from "react"
import { useAuth } from "../../../../contexts/AuthContext"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {useProject} from "../../../../contexts/ProjectContext";
import LanguageSelector from "../../../../i18n/LanguageSelector";
import {useTranslation} from "react-i18next";

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [passwordResetSend, setPasswordResetSend] = useState(false);
  const [wrongAccessData, setWrongAccessData] = useState(false);
  const action = searchParams.get("action");
  const {triggerProjectReload} = useProject();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (action === "passwordResetSend") {
      setPasswordResetSend(true);
    }
  }, [action]);

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setLoading(true)
      const userItem = await login(emailRef.current.value, passwordRef.current.value)
      await triggerProjectReload(userItem.user)
      navigate("/")
    } catch (e) {
      setWrongAccessData(true);
      setLoading(false);
      passwordRef.current.value = "";
    }
  }

  return (
    <>
      <div className="appHeader no-border transparent position-absolute">
        <LanguageSelector />
      </div>
      <div id="appCapsule">
        <div className="section">
          <img src="https://app.pollopi.com/assets/img/icon/logo.svg" alt="Logo" border="0" width="100"
               style={{display: "block", width: "100px", maxWidth: "100px", minWidth: "100px", borderRadius: "6px", marginLeft: "auto", marginRight: "auto"}} />
        </div>
        <div className="section mt-5 text-center">
          <h1>{i18n.t("login")}</h1>
        </div>
        <div className="section mb-5 p-2">
          {passwordResetSend && (
            <div className="alert alert-imaged alert-info alert-dismissible fade show mb-3" role="alert">
              <div className="icon-wrap">
                <ion-icon name="mail-outline" role="img" className="md hydrated" aria-label="card outline"></ion-icon>
              </div>
              <div>
                {i18n.t("check_your_inbox")}
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
          {wrongAccessData && (
            <div className="alert alert-imaged alert-danger alert-dismissible fade show mb-3" role="alert">
              <div className="icon-wrap">
                <ion-icon name="key-outline" role="img" className="md hydrated" aria-label="card outline"></ion-icon>
              </div>
              <div>
                {i18n.t("wrong_access_data")}
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body pb-1">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">{i18n.t("email")}</label>
                    <input type="email" className="form-control" id="email1" placeholder={i18n.t("your_e-mail")} ref={emailRef} required />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="password1">{i18n.t("password")}</label>
                    <input type="password" className="form-control" id="password1" autoComplete="off" placeholder={i18n.t("your_password")} ref={passwordRef} required />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                  </div>
                </div>
              </div>
            </div>


            <div className="form-links mt-2">
              <div>
                <Link to={"/signup"} >{i18n.t("register_now")}</Link>
              </div>
              <div><Link to={"/forgot-password"} className="text-muted">{i18n.t("forgot_password")}</Link></div>
            </div>

            <div className="form-button-group  transparent">
              <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={loading} >{i18n.t("login.button")}</button>
            </div>

          </form>
        </div>

      </div>
    </>
  )
}
