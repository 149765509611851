import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function MemberList({memberList}) {
  const { t, i18n } = useTranslation();
  return (
    <div className="transactions">
      {memberList.map((member => (<Link key={member.ID} to={""} className="item">
          <div className="detail">
            <div>
              <strong>{member.Firstname + " " + member.Lastname}</strong>
            </div>
          </div>
        </Link>)))}
    </div>
  );
}
