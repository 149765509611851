import React, {useEffect, useRef, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {getResponseData, numberToEnglishDecimal} from "../../../../../functions/functions";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import Loader from "../../../Layout/Loader/Loader";
import InfoTooltip from "../../../Layout/Tooltip/InfoTooltip";
import {useTranslation} from "react-i18next";

export default function OwnconsumptionEdit() {
  const navigate = useNavigate();
  const {ID} = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const [customer, setCustomer] = useState();
  const [customerName, setCustomerName] = useState();
  const [number, setNumber] = useState();
  const [price, setPrice] = useState(0.00);
  const [isDeposit, setIsDeposit] = useState(false);
  const [date, setDate] = useState("0");
  const [showDateField, setShowDateField] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  const [showUserDeleted, setShowUserDeleted] = useState(true);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/detail`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const raw = JSON.parse(result);
          const data = raw.IncomeEntry;
          setPaymentStatus(data.PaymentStatusList);
          setCustomer(data.CustomerList);
          setCustomerName(data.Customer);
          setSelectedPaymentStatus(data.PaymentStatusID);
          setDate(data.Created.split(' ')[0]);
          if (data.PaymentStatusID === 4) {
            setShowDateField(true);
          }
          let help = 0;
          data.CustomerList.forEach(customer => {
            if (customer.ID === data.CustomerID) {
              help += 1;
            }
          })
          if (help) {
            setShowUserDeleted(false)
          }
          setSelectedCustomer(data.CustomerID)
          setNumber(data.Number)
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      id: ID,
      selectedCustomer: selectedCustomer,
      number: number,
      date: date,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/edit`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate("../transactions");
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  function handleIsDeposit(e) {
    setIsDeposit(e.target.checked)
    setShowDateField(false)
    setNumber(0);
    setSelectedPaymentStatus(5);
  }

  function handlePrice(e) {
    setPrice(numberToEnglishDecimal(e.target.value))
  }

  function handlePaymentStatus(e) {
    setSelectedPaymentStatus(e.target.value)
    if (e.target.value == 4) {
      setShowDateField(true);
    } else {
      setShowDateField(false);
    }
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("edit_entry")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{i18n.t("customer")}</label>
                        <select className="form-control custom-select" id="select4" value={selectedCustomer}
                                onChange={e => setSelectedCustomer(e.target.value)} required={true}>
                          {customer.map((entry => (
                            <option key={entry.ID} value={entry.ID}>{entry.Firstname + " " + entry.Lastname}</option>
                          )))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("number")}</label>
                        <input type="number" className="form-control" id="text4" placeholder={i18n.t("number_example")}
                               onChange={e => setNumber(e.target.value)} value={number || ''} required={true}/>
                      </div>
                    </div>
                    {showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("reserved_for")}</label>
                          <input type="date" className="form-control" id="date" placeholder="z.B. 3,00"
                                 onChange={e => setDate(e.target.value)} value={date} required={true}/>
                        </div>
                      </div>
                    )}
                    {!showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("date")}</label>
                          <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)}
                                 value={date} required={true}/>
                        </div>
                      </div>
                    )}

                    <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
