import React, {useEffect, useState} from "react"
import ChartElement from "./ChartElement";
import {useAuth} from "../../../../contexts/AuthContext";
import Header from "../../Layout/Header/Header";
import ValueOverview from "./ValueOverview";
import StatisticsList from "./StatisticsList";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../functions/functions";
import Loader from "../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";
import ReportValueOverview from "./ReportValueOverview";

export default function StatisticsPage() {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState();
  const [entries, setEntries] = useState([]);
  const [val, setVal] = useState();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/statistics/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setVal(data.Values);
          setEntries(data.Entries);
          setChartData(data.ChartData);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("statistics")}/>
          <div className="extraHeader pe-0 ps-0">
            <ul className="nav nav-tabs lined" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#overview" role="tab" aria-selected="false">
                  {i18n.t("overview")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#report" role="tab" aria-selected="true">
                  {i18n.t("report")}
                </a>
              </li>
            </ul>
          </div>
          <div id="appCapsule" className={"extra-header-active full-height"}>
            <div className={`tab-content mt-2 mb-1`}>
            <div className="tab-pane show active" id="overview" role="tabpanel">
              {/*TODO Durschnitt angeben*/}
              <ValueOverview val={val}/>
              <ChartElement chartData={chartData}/>
              <StatisticsList entries={entries}/>
              <LegalFooter/>
            </div>

            <div className="tab-pane show" id="report" role="tabpanel">
              {/*TODO Durschnitt angeben*/}
              <ReportValueOverview val={val}/>
            </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
