import React from "react";
import {useAuth} from "../../contexts/AuthContext";
import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useProject} from "../../contexts/ProjectContext";

export default function PrivateRoutes() {
  const { currentUser } = useAuth();
  const { project } = useProject();
  let location = useLocation();

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (typeof project === 'undefined') {
    return <Navigate to="/welcome" state={{ from: location }} />;
  }

  return <Outlet />;
}
