import React, {useEffect, useState} from "react"
import {useAuth} from "../../../../contexts/AuthContext"
import Header from "../../Layout/Header/Header";
import HenhouseTab from "./HenhouseTab";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import {
  customizeDate,
  customizeDateForComparisons,
  getCurrencySignByCode,
  getResponseData
} from "../../../../functions/functions";
import Loader from "../../Layout/Loader/Loader";
import {useProject} from "../../../../contexts/ProjectContext";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useCookies} from "react-cookie";
import DashboardStatsBox from "../../Layout/Tooltip/DashboardStatsBox";
import {Link} from "react-router-dom";

export default function Dashboard() {
  const {currentUser} = useAuth()
  const {project} = useProject()
  const [isLoading, setIsLoading] = useState(false)
  const [overviewValues, setOverviewValues] = useState()
  const {t, i18n} = useTranslation();
  const [showListPermanently, setShowListPermanently] = useState(false);
  const [reservedListShowUntilValue, setReservedListShowUntilValue] = useState("7");
  const [allEggsReserved, setAllEggsReserved] = useState("0");
  const [incomeReservedList, setIncomeReservedList] = useState([]);
  const [cookies, setCookie] = useCookies(['showListPermanently', 'reservedListShowUntilValue', 'reservedListShowUntilDate']);

  function handleShowListPermanently(e) {
    setShowListPermanently(e.target.checked)
    if (e.target.checked) {
      setCookie('showListPermanently', true, {path: '/'})
    } else {
      setCookie('showListPermanently', false, {path: '/'})
    }
    const body = {
      showListPermanently: e.target.checked,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/profile/editShowListPermanently`, getResponseData(body))
  }

  function triggerReservedList(obj, dateForComparisons) {
    let count = 0;
    let list = [];
    obj.IncomeReservedList.map((entry => {
      if (customizeDateForComparisons(entry.Created) <= dateForComparisons) {
        list.push(entry);
        count += parseInt(entry.Number);
      }
    }))
    setIncomeReservedList(list);
    setAllEggsReserved(count)
  }

  function reservedListShowUntil(e = null, date = null) {
    let eTargetValue = 7;
    if (e !== null && date == null) {
      eTargetValue = e.target.value;
    }
    if (date !== null) {
      eTargetValue = date;
    }
    let dateForComparisons = "";
    let number = eTargetValue < moment().isoWeekday() ? parseInt(eTargetValue) + 7 : eTargetValue;
    let day = "" + moment().day(number).get('date');
    let month = "" + (moment().day(number).get('month') + 1);
    let year = "" + moment().day(number).get('year');
    dateForComparisons = year + "" + (month.length > 1 ? month : "0" + month) + "" + (day.length > 1 ? day : "0" + day);

    return dateForComparisons;
  }

  function triggerReloadReservedList(e) {
    setCookie('reservedListShowUntilDate', reservedListShowUntil(e), { path: '/' })
    setCookie('reservedListShowUntilValue', e.target.value, { path: '/' })
  }

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }
    fetch(`https://api.pollopi.com/dashboard/`, getResponseData(body))
      .then(res => res.json())
      .then(
        async (result) => {
          const data = JSON.parse(result);
          setOverviewValues(data.OverviewValues);
          if (cookies.showListPermanently === "true" || project.ShowListPermanently) {
            setShowListPermanently(true)
          } else {
            setShowListPermanently(false)
          }
          if (project.ReservedListShowUntilValue !== 0) {
            if (cookies.reservedListShowUntilValue) {
              triggerReservedList(data.OverviewValues, reservedListShowUntil(null, cookies.reservedListShowUntilValue))
              setReservedListShowUntilValue(cookies.reservedListShowUntilValue)
            } else {
              triggerReservedList(data.OverviewValues, reservedListShowUntil(null, project.ReservedListShowUntilValue))
              setReservedListShowUntilValue(project.ReservedListShowUntilValue)
            }
          } else {
            triggerReservedList(data.OverviewValues, reservedListShowUntil())
          }
          setIsLoading(false);
        },
        () => {
          setIsLoading(true);
        }
      )
  }, [reservedListShowUntilValue])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("dashboard")}/>
          {project.ShowChickenCoopTab ? (
            <div className="extraHeader pe-0 ps-0">
              <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#overview" role="tab" aria-selected="false">
                    {i18n.t("overview")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#henhouse" role="tab" aria-selected="true">
                    {i18n.t("chicken_coop")}
                  </a>
                </li>
              </ul>
            </div>
          ) : ""}
          <div id="appCapsule" className={`${project.ShowChickenCoopTab ? "extra-header-active" : ""} full-height`}>
            <div className={`section tab-content mt-2 mb-1`}>
              {project.ShowChickenCoopTab ? (
                <HenhouseTab/>
              ) : ""}
              {overviewValues && (
                <div className="tab-pane fade active show" id="overview" role="tabpanel">
                  <div className="row">
                    <div className="col-6">
                      <DashboardStatsBox title={i18n.t("today")} tooltip={i18n.t("today_tooltip")}
                                         value={overviewValues.Today} id={overviewValues.TodayID} color={"text-success"} alignment={"bottom"}/>
                    </div>
                    <div className="col-6">
                      <div className="stat-box">
                        <div className="title">{i18n.t("seven_days")}</div>
                        <div className="value text-warning">{overviewValues.LastSevenDays}</div>
                      </div>
                    </div>
                  </div>
                  {!project.DisableTransactions && (
                    <>
                      <div className="section-heading mt-2">
                        <h2 className="title">{i18n.t("sales_overview")}</h2>
                        {/*<a href="app-transactions.html" className="link">View All</a>*/}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <DashboardStatsBox title={i18n.t("in_stock")} tooltip={i18n.t("in_stock_tooltip")}
                                             value={overviewValues.InStock} color={"text-info"} alignment={"top"}/>
                        </div>
                        <div className="col-6">
                          <div className="stat-box">
                            <div className="title">{i18n.t("reserved")}</div>
                            <div className="value text-secondary">{allEggsReserved}</div>
                          </div>
                        </div>
                      </div>
                      <div className="section inset mt-1 p-0">
                        <div className="section-heading mt-4">
                          <h2 className="title">{i18n.t("reserved")}</h2>
                          <a href="#" className="btn btn-primary" data-bs-toggle="modal"
                             data-bs-target="#PanelRight">{i18n.t("filter")}&nbsp;
                            <ion-icon style={{fontSize: "18px !important", marginRight: 0}}
                                      name="filter-circle-outline"/>
                          </a>
                        </div>
                        <div className="accordion" id="accordionExample1">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button className={`accordion-button ${showListPermanently ? "" : "collapsed"}`}
                                      type="button" data-bs-toggle="collapse"
                                      data-bs-target="#accordion01">
                                {i18n.t("show_list")}
                              </button>
                            </h2>
                            <div id="accordion01"
                                 className={`accordion-collapse collapse ${showListPermanently ? "show" : ""}`}
                                 data-bs-parent="#accordionExample1">
                              <div className="accordion-body">
                                <div className="transactions mb-2">
                                  {incomeReservedList.length > 0 ? (
                                    incomeReservedList.map((entry => (
                                      <Link key={entry.ID} to={"../transactions/income/detail/" + entry.ID}
                                            style={{boxShadow: "1px 2px 5px 1px rgb(0 0 0 / 9%)"}} className="item">
                                        <div className="detail">
                                          <div>
                                            <strong>{entry.Customer}</strong>
                                            <p>{i18n.t("reserved_for")} {customizeDate(entry.Created)}</p>
                                          </div>
                                        </div>
                                        <div style={{position: "absolute", right: "30%"}}
                                             className={"text-secondary"}>
                                          {entry.Number} {i18n.t("eggs")}
                                        </div>
                                        <div className="right">
                                          <div
                                            className={`price text-secondary`}>{entry.Price} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</div>
                                        </div>
                                      </Link>
                                    )))) : (
                                    <span className="item" style={{boxShadow: "1px 2px 5px 1px rgb(0 0 0 / 9%)"}}>
                          <div className="detail">
                            <div>
                              <strong>{i18n.t("no_eggs")}</strong>
                            </div>
                          </div>
                        </span>
                                  )}
                                </div>
                                <div className="content-header" style={{color: "#958d9e"}}>
                                  {i18n.t("shows_all_reserved_eggs_notice")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="content-header mt-2" style={{color: "#958d9e"}}>

                      </div>
                      {/*TEST*/}
                    </>
                  )}
                  <div className="modal fade panelbox panelbox-right" id="PanelRight" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">{i18n.t("filter")}</h5>
                          <a href="#" data-bs-dismiss="modal">{i18n.t("close")}</a>
                        </div>
                        <div className="modal-body">
                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="select4">{i18n.t("display_settings")}</label>
                              <div className="form-check mb-1 mt-1">
                                <input type="checkbox" checked={showListPermanently}
                                       onChange={handleShowListPermanently} className="form-check-input"
                                       id="customCheckb1"/>
                                <label className="form-check-label"
                                       htmlFor="customCheckb1">{i18n.t("show_list_permanently")}</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="select4">{i18n.t("show_until")}</label>
                              <select className="form-control custom-select" id="select4"
                                      onChange={(e) => {
                                        triggerReloadReservedList(e);
                                        setReservedListShowUntilValue(e.target.value)
                                        const body = {
                                          reservedListShowUntilValue: e.target.value,
                                          uid: currentUser.uid,
                                        }

                                        fetch(`https://api.pollopi.com/settings/profile/editReservedListShowUntilValue`, getResponseData(body))
                                      }} value={reservedListShowUntilValue}
                                      required={true}>
                                <option key={1} value={1}>{i18n.t("monday")}</option>
                                <option key={2} value={2}>{i18n.t("tuesday")}</option>
                                <option key={3} value={3}>{i18n.t("wednesday")}</option>
                                <option key={4} value={4}>{i18n.t("thursday")}</option>
                                <option key={5} value={5}>{i18n.t("friday")}</option>
                                <option key={6} value={6}>{i18n.t("saturday")}</option>
                                <option key={7} value={7}>{i18n.t("sunday")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  )
}
