import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getResponseData} from "../../../../functions/functions";
import {useAuth} from "../../../../contexts/AuthContext";
import {useProject} from "../../../../contexts/ProjectContext";
import {useTranslation} from "react-i18next";

export default function Navbar() {
  let location = useLocation();
  const {currentUser} = useAuth()
  const {reload} = useProject()
  let currentLocation = location.pathname.split("/")[1];
  const [isLoading, setIsLoading] = useState(true);
  const [disableTransactions, setDisableTransactions] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/project/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setDisableTransactions(data.Project.DisableTransactions)
          setShowStock(data.Project.ShowStock)
          setIsLoading(false);
        },
        (error) => {
          console.log(currentUser.uid)
          setIsLoading(true);
        }
      )
  }, [reload])

  return (
    <>
      {!isLoading && (
        <>
          <div className="appBottomMenu">
            <Link to={`/`} title={i18n.t("dashboard")} className={`item ${currentLocation ? "" : "active"}`}>
              <div className="col">
                <ion-icon name="home-outline"/>
                <strong>{i18n.t("dashboard")}</strong>
              </div>
            </Link>
            <Link to={`/statistics`} title={i18n.t("statistics")}
                  className={`item ${currentLocation === "statistics" ? "active" : ""}`}>
              <div className="col">
                <ion-icon name="stats-chart-outline"/>
                <strong>{i18n.t("statistics")}</strong>
              </div>
            </Link>
            <Link to={`/stock`} title={i18n.t("stock")} className={`item ${currentLocation === "stock" ? "active" : ""}`}>
              <div className="col">
                <ion-icon name="egg-outline"/>
                <strong>{i18n.t("stock")}</strong>
              </div>
            </Link>
            {!disableTransactions && (
              <Link to={`/transactions`} title={i18n.t("transactions")}
                    className={`item ${currentLocation === "transactions" ? "active" : ""}`}>
                <div className="col">
                  <ion-icon name="wallet-outline"/>
                  <strong>{i18n.t("transactions")}</strong>
                </div>
              </Link>
            )}
            <Link to={`/settings`} title={i18n.t("settings")}
                  className={`item ${currentLocation === "settings" ? "active" : ""}`}>
              <div className="col">
                <ion-icon name="settings-outline"/>
                <strong>{i18n.t("settings")}</strong>
              </div>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
