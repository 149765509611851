import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {useAuth} from "../../../../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {getLocalizedName, getResponseData, post} from "../../../../functions/functions";
import Loader from "../../Layout/Loader/Loader";
import {useProject} from "../../../../contexts/ProjectContext";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";

export default function Settings() {
  const {currentUser, logout} = useAuth();
  const {project} = useProject();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['i18n_locale']);

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setUser(data);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  async function handleLogout() {
    await logout();
    navigate("/login");
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("settings")}/>
          <div id="appCapsule">
            <div className="section mt-3">
              <div className="section-title large">{user.Firstname + " " + user.Lastname}</div>
            </div>

            {project.IsBetaProject && (
              <>
                {!project.IsProAccount && (
                  <>
                    <div className="listview-title mt-1">{"Pro-Account"}</div>
                    <ul className="listview link-listview inset mb-2">
                      <li>
                        <Link to={"../pro-plan"} className="item" style={{backgroundColor: "#8CC152"}}>
                          <div className="in">
                            <div>{i18n.t("pollopi_pro")}</div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </>
            )}

            <div className="listview-title mt-1">{i18n.t("project")}</div>
            <ul className="listview link-listview inset mb-2">
              <li>
                <Link to={"../settings/project/edit"} className="item">
                  <div className="in">
                    <div>{i18n.t("project_name")}</div>
                    <span className="text-primary">{user.Project.Title}</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"../settings/project/member"} className="item">
                  <div className="in">
                    <div>{i18n.t("members")}</div>
                    <span className="text-primary">{user.Project.Number}</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"../settings/project/edit"} className="item">
                  <div className="in">
                    <div>{i18n.t("edit_project")}</div>
                  </div>
                </Link>
              </li>
            </ul>

            <div className="listview-title mt-1">{i18n.t("profile")}</div>
            <ul className="listview link-listview inset mb-2">
              <li>
                <Link to={"/settings/profile/update-email"} className="item">
                  <div className="in">
                    <div>{i18n.t("email")}</div>
                    <span className="text-primary">{currentUser ? currentUser.email : ""}</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"../settings/profile/language/edit"} className="item">
                  <div className="in">
                    <div>{i18n.t("profile_language")}</div>
                    <span className="text-primary">{getLocalizedName(cookies.i18n_locale)}</span>
                  </div>
                </Link>
              </li>
            </ul>

            <div className="listview-title mt-1">{i18n.t("security")}</div>
            <ul className="listview link-listview inset mb-2">
              <li>
                <Link to={"/settings/profile/update-password"} className="item">
                  <div className="in">
                    <div>{i18n.t("change_password")}</div>
                  </div>
                </Link>
              </li>
              <li onClick={() => handleLogout()}>
                <a className="item">
                  <div className="in">
                    <div>{i18n.t("logout")}</div>
                  </div>
                </a>
              </li>
            </ul>

            {project.IsProAccount && false && (
              <>
                <div className="listview-title mt-1">{i18n.t("subscription")}</div>
                <ul className="listview link-listview inset mb-2">
                  <li>
                    <a onClick={() => post("https://api.pollopi.com/stripe/createCustomerPortalSession", {
                      customerEmail: currentUser.email
                    })} className="item">
                      <div className="in">
                        <div>{i18n.t("subscription_settings")}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </>
            )}

            <div className="listview-title mt-1">{i18n.t("faq_and_legal")}</div>
            <ul className="listview link-listview inset mb-2">
              {project.IsBetaProject && (
                <li>
                  <span onClick={() => {
                    window.Intercom("showArticle", 6066709)
                  }} className="item">
                    <div className="in">
                      <div>Hilfe-Center</div>
                    </div>
                  </span>
                </li>
              )}
              <li>
                <a href="https://help.pollopi.com/" target="_blank" className="item">
                  <div className="in">
                    <div>{i18n.t("help-center")}</div>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.pollopi.com/legal-notice" target="_blank" className="item">
                  <div className="in">
                    <div>{i18n.t("site_notice")}</div>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.pollopi.com/privacy-policy" target="_blank" className="item">
                  <div className="in">
                    <div>{i18n.t("privacy_policy")}</div>
                  </div>
                </a>
              </li>
            </ul>
            <div className={"section"}>
              {project.IsProAccount && (
                <p style={{textAlign: "center"}}>{i18n.t("status")}: <span style={{color: "#8CC152"}}>{i18n.t("pro_account")}</span></p>
              )}
              {!project.IsProAccount && (
                <p style={{textAlign: "center"}}>{i18n.t("status")}: {i18n.t("basis_account")}</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
