import React from "react";
import {useTranslation} from "react-i18next";

export default function ReportValueOverview({val}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="section mt-1">
        <div className="section-title mt-2">{i18n.t("laid_eggs")}</div>
        <p>{i18n.t("shows_the_laid_eggs_in_the_respective_period")}</p>
        <div className="row mt-1">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("today")}</div>
              <div className="value text-success">{val.Today}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("seven_days")}</div>
              <div className="value text-warning">{val.LastSevenDays}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section mt-1">
        <div className="row mt-2">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("thirty_days")}</div>
              <div className="value text-info">{val.LastThirtyDays}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("three_month")}</div>
              <div className="value text-info">{val.LastThreeMonth}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section mt-1">
        <div className="row mt-2">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("six_month")}</div>
              <div className="value text-info">{val.LastSixMonth}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("all")}</div>
              <div className="value text-info">{val.All}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section mt-2">
        <div className="section-title">{i18n.t("laying_performance_per_chicken")}</div>
        <p>{i18n.t("shows_how_many_eggs_a_hen_laid_on_average_in_the_respective_period")}</p>
        <div className="row mt-1">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("today")}</div>
              <div className="value text-info">{val.LayingPerformance.TodayPerChicken} %</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("seven_days")}</div>
              <div className="value text-info">{val.LayingPerformance.LastSevenDaysPerChicken} %</div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("thirty_days")}</div>
              <div className="value text-info">{val.LayingPerformance.LastThirtyDaysPerChicken} %</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("three_month")}</div>
              <div className="value text-info">{val.LayingPerformance.LastThreeMonthsPerChicken} %</div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("six_month")}</div>
              <div className="value text-info">{val.LayingPerformance.LastSixMonthsPerChicken} %</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("all")}</div>
              <div className="value text-info">{val.LayingPerformance.AllDaysPerChicken} %</div>
            </div>
          </div>
        </div>
        <div className="row mt-2"></div>
      </div>
    </>
  )
}