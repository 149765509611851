import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {getCurrencySignByCode, getResponseData, numberToEnglishDecimal} from "../../../../../functions/functions";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../../contexts/ProjectContext";

export default function ExpenseEdit() {
  const navigate = useNavigate();
  const {ID} = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState(0.00);
  const [title, setTitle] = useState("");
  const [notice, setNotice] = useState("");
  const [date, setDate] = useState("");
  const [isCompensationValue, setIsCompensationValue] = useState(false);
  const { t, i18n } = useTranslation();
  const {project} = useProject();


  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/expenses/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const raw = JSON.parse(result);
          const data = raw.ExpenseEntry;
          setTitle(data.Title)
          setPrice(numberToEnglishDecimal(data.Price))
          setNotice(data.Notice)
          setDate(data.Created.split(' ')[0]);
          setIsLoading(false);
          setIsCompensationValue(data.IsCompensationValue)
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      id: ID,
      title: title,
      price: price,
      notice: notice,
      date: date,
      isCompensationValue: isCompensationValue,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/expenses/edit/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate("../transactions");
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  function handlePrice(e) {
    setPrice(numberToEnglishDecimal(e.target.value))
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("edit_entry")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("title")}</label>
                      <input type="text" className="form-control" id="expanses_title"
                             placeholder={i18n.t("title_example")} onChange={e => setTitle(e.target.value)}
                             value={title || ''} required={true}/>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("price_in")} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</label>
                      <input type="number" className="form-control" id="expanses_price"
                             placeholder={i18n.t("price_example")} onChange={handlePrice} value={price || ''} required={true}  step="0.01"/>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="textarea4">{i18n.t("note")}</label>
                      <textarea id="expanses_note" rows="5" className="form-control"
                                placeholder={i18n.t("note_example")} value={notice}
                                onChange={e => setNotice(e.target.value)}/>
                      <i className="clear-input">
                        <ion-icon name="close-circle" role="img" className="md hydrated"
                                  aria-label="close circle"/>
                      </i>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("date")}</label>
                      <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)} value={date} required={true}/>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}
                  </button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
