import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function CustomerNew()
{
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState();
  const { t, i18n } = useTranslation();

  {/*TODO Adresse hinzufügen*/}

  useEffect(() => {
    setIsLoading(false)
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      uid: currentUser.uid,
      name: name,
    }

    fetch(`https://api.pollopi.com/transactions/customers/new/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate("../transactions");
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("new_customer")} showBackButton={true} />
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("name")}</label>
                      <input type="text" className="form-control" id="text4" placeholder={i18n.t("name_example")} onChange={e => setName(e.target.value)} value={name || ""} required={true}/>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}
