import React, {useEffect, useState} from "react";
import TransactionsListEntry from "./TransactionsListEntry";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {getResponseData} from "../../../../functions/functions";
import {useAuth} from "../../../../contexts/AuthContext";
import {useProject} from "../../../../contexts/ProjectContext";

export default function TransactionsList({entries})
{
  const [sliceValue, setSliceValue] = useState(7);
  const [showButton, setShowButton] = useState(true);
  const [showShowAllButton, setShowShowAllButton] = useState(false);
  const [showReserved, setShowReserved] = useState(true);
  const [transactionsList, setTransactionsList] = useState(false);
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['showReserved']);
  const {currentUser} = useAuth()
  const {project} = useProject()

  useEffect(() => {
    if (cookies.showReserved === "true" || project.ShowReserved) {
      setShowReserved(true)
      setTransactionsList(entries.TransactionsList);
      if (entries.TransactionsList.length < sliceValue) {
        setShowButton(false);
        setShowShowAllButton(false);
      }
    } else {
      setShowReserved(false)
      setTransactionsList(entries.TransactionsListDoNotShowReserved);
      if (entries.TransactionsListDoNotShowReserved.length < sliceValue) {
        setShowButton(false);
        setShowShowAllButton(false);
      }
    }
  },[])

  function changeListViewShowAll() {
    setSliceValue(entries.length);
    setShowButton(false);
  }

  function changeListView() {
    setSliceValue(sliceValue+7);
    if (sliceValue+7>=transactionsList.length) {
      setShowButton(false);
    }
  }

  function handleShowReserved(e) {
    setShowReserved(e.target.checked)
    if (e.target.checked) {
      setCookie('showReserved', true, { path: '/' })
      setTransactionsList(entries.TransactionsList);
    } else {
      setCookie('showReserved', false, { path: '/' })
      setTransactionsList(entries.TransactionsListDoNotShowReserved);
    }
    const body = {
      showReserved: e.target.checked,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/profile/editShowReserved`, getResponseData(body))
  }

  return(
    <div className="section mt-4 mb-4">
        <div className="section-heading">
          <h2 className="title">{i18n.t("history")}</h2>
          <a href="#" className="btn btn-primary" data-bs-toggle="modal"
             data-bs-target="#PanelRight">{i18n.t("filter")}&nbsp;
            <ion-icon style={{fontSize: "18px !important", marginRight: 0}}
                      name="filter-circle-outline"/>
          </a>
        </div>
        {showShowAllButton && (<span onClick={changeListViewShowAll} className="link">i18n.t("show_all")</span>)}
      <div className="transactions">
        {transactionsList && transactionsList.length > 0 ? (
          transactionsList.slice(0,sliceValue).map((entry => (
            <TransactionsListEntry entry={entry} key={entry.ID} />
          )))
        ) : (
          i18n.t("no_income_or_expenses")
        )}

        {showButton && (<div className="pt-1">
          <span onClick={changeListView} className="btn btn-block btn-outline-secondary">{i18n.t("more")}</span>
        </div>)}
      </div>
    </div>
  );
}
