import React, {useEffect, useState} from "react";
import TawkTo from 'tawkto-react'
import {Link, useNavigate} from 'react-router-dom';
import {useAuth} from "../../../../contexts/AuthContext";
import {useProject} from "../../../../contexts/ProjectContext";
import {useIntercom} from 'react-use-intercom';
import {getResponseData} from "../../../../functions/functions";
import {trackPageView} from "../../../../functions/intercom";
import {useTranslation} from 'react-i18next';
import {useCookies} from "react-cookie";

export default function Header({
                                 title,
                                 showBackButton,
                                 showDetailHeader,
                                 dataBsTarget,
                                 editNavigateTo,
                                 doNotShowNotificationsButton,
                                 notificationsNumber,
                                 deactivateChat,
                                 addExpense,
                                 addIncome
                               }) {
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const {project} = useProject()
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [emptyState, setEmptyState] = useState(false);
  let tawkHasAProblem = true;
  const {boot, shutdown, hide, show, update} = useIntercom();
  const {t, i18n} = useTranslation();
  const [cookies, setCookie] = useCookies(['i18n_locale']);

  document.title = title + " - Pollopi";

  trackPageView(title);

  function initIntercom() {
    const body = {
      uid: currentUser.uid,
    }
    fetch("https://api.pollopi.com/settings/profile/getIntercomHash", getResponseData(body))
      .then(res => res.json())
      .then(async (hash) => {
          window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "m68axokr",
            user_id: currentUser.uid,
            user_hash: hash,
            name: project.Firstname + " " + project.Lastname,
            email: currentUser.email,
            language_override: cookies.i18n_locale,
            created_at: Math.floor(currentUser.metadata.createdAt / 1000),
            company: {
              company_id: project.ID,
              name: project.Title,
              size: project.Number,
            },
            hide_default_launcher: true,
          })
        }
      )
  }

  useEffect(() => {
    initIntercom();
  })

  return (
    <div className="appHeader">
      {showBackButton && (
        <div className="left">
        <span onClick={() => navigate(-1)} className="headerButton goBack">
          <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"/>
        </span>
        </div>
      )}
      <div className="pageTitle">
        {title}
      </div>
      <div className="right">
        {!showDetailHeader && (
          <>
            {!doNotShowNotificationsButton && false && (
              <Link to={"../notifications"} className="headerButton">
                <ion-icon className="icon" name="notifications-outline"/>
                <span className="badge badge-danger">{notificationsNumber}</span>
              </Link>
            )}
          </>
        )}
        {showDetailHeader && (
          <>
            {editNavigateTo && (
              <Link to={editNavigateTo} className="headerButton btn-text-info">
                <ion-icon name="create-outline" role="img" className="md hydrated"
                          aria-label="create-outline"/>
              </Link>
            )}
            {dataBsTarget && (<a href="#" className="headerButton btn-text-danger" data-bs-toggle="modal"
                                 data-bs-target={"#" + dataBsTarget}>
              <ion-icon name="trash-outline" role="img" className="md hydrated" aria-label="trash outline"/>
            </a>)}
          </>
        )}
        {addIncome && (<Link to={"../../transactions/income/new"} className="headerButton btn-text-success">
          <ion-icon name="add-circle-outline"/>
        </Link>)}
        {addExpense && (<Link to={"../../transactions/expenses/new"} className="headerButton btn-text-success">
          <ion-icon name="add-circle-outline"/>
        </Link>)}
        {!deactivateChat && (
          <span onClick={() => {
            window.Intercom('show');
          }} className="headerButton btn-text-info">
            <ion-icon name="help-circle-outline"/>
            <span className="badge badge-success">{showNewMessage && "+"}</span>
          </span>
        )}
      </div>
    </div>
  );
}
