import {Overlay, Tooltip} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export default function InfoTooltip({text, fontsize, fontcolor}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { t, i18n } = useTranslation();

  return(
    <>
      <ion-icon ref={target} onClick={() => setShow(!show)} style={{fontSize: fontsize, marginRight: "auto", marginLeft: "5px", color: fontcolor}} name="information-circle-outline"/>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}