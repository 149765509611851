import React, {useContext, useEffect, useState} from "react"

import {getResponseData} from "../functions/functions";
import {useAuth} from "./AuthContext";
import {useCookies} from "react-cookie";

const ProjectContext = React.createContext()

export function useProject() {
  return useContext(ProjectContext)
}

export function ProjectProvider({ children }) {
  const {currentUser} = useAuth()
  const [project, setProject] = useState()
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true);
  /* eslint-disable no-unused-vars */
  const [cookies, setCookie] = useCookies(['i18n_locale']);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    const fetchData = async () => {
      await setupProject()
    }
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function triggerProjectReload(userItem = null) {
    await setupProject(userItem)
  }

  async function setupProject(userItem = null) {
    setIsLoading(true)
    if(!(currentUser == null) || !(userItem == null)) {
      let body;
      if (!(currentUser == null)) {
        body = {
          uid: currentUser.uid,
        }
      } else {
        body = {
          uid: userItem.uid,
        }
      }

      fetch(`https://api.pollopi.com/settings/project/detail/`, getResponseData(body))
        .then(res => res.json())
        .then(
          (result) => {
            const data = JSON.parse(result);
            setProject(data.Project)
            setUser(data.User)
            setCookie('i18n_locale', data.LanguageCode, { path: '/' })
            setCookie('showReserved', data.ShowReserved, { path: '/' })
            setIsLoading(false)
          }
        )
    } else {
      setIsLoading(false)
    }
  }

  const value = {
    project,
    user,
    triggerProjectReload,
  }

  return (
    <ProjectContext.Provider value={value}>
        {!isLoading && children}
    </ProjectContext.Provider>
  )
}
