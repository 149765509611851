import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {useAuth} from "../../../../contexts/AuthContext";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import Loader from "../../Layout/Loader/Loader";
import {getResponseData, post} from "../../../../functions/functions";
import {useProject} from "../../../../contexts/ProjectContext";
import {useTranslation} from "react-i18next";

export default function UserData() {
  const {currentUser} = useAuth()
  const {project} = useProject()
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const { t, i18n } = useTranslation();

  const regionNames = new Intl.DisplayNames(
    ['de'], {type: 'region'}
  );

  useEffect(() => {
    fetch(`https://api.pollopi.com/stripe/countryList`)
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setCountryList(data.list.data)
          setName(project.Firstname + " " + project.Lastname)
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
    setCountry("DE");
  }, [])

  function handleInput(e) {
    setEmail(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault()
    setDisableButton(true);
    const body = {
      "name": name,
      "email": currentUser.email,
      "address": {
        "city": city,
        "country": country,
        "line1": line1,
        "line2": line2,
        "postal_code": postalCode,
        "state": state
      }
    };
    fetch(`https://api.pollopi.com/stripe/createCustomer`, getResponseData(body))
      .then(res => res.json())
      .then(
        async (result) => {
          console.log(result)
          post("https://api.pollopi.com/stripe/createCheckoutSession", {
            customerEmail: currentUser.email
          })
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={"Pro-Plan"} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Vor- Nachname / Firma"} <span
                          style={{color: "red"}}>*</span></label>
                        <input type="text" className="form-control" id="line1" placeholder={""}
                               value={name} onChange={(e) => setName(e.target.value)} required={true}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Adresszeile 1"} <span style={{color: "red"}}>*</span></label>
                        <input type="text" className="form-control" id="line1"
                               placeholder={"Straße, Postfach oder Firmenname"}
                               value={line1} onChange={(e) => setLine1(e.target.value)} required={true}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Adresszeile 2"}</label>
                        <input type="text" className="form-control" id="line2" placeholder={"Einheit oder Gebäude"}
                               value={line2} onChange={(e) => setLine2(e.target.value)}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Postleitzahl"} <span style={{color: "red"}}>*</span></label>
                        <input type="number" className="form-control" id="postal_code"
                               placeholder={"Postleitzahl"} value={postalCode}
                               onChange={(e) => setPostalCode(e.target.value)} required={true}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Stadt"} <span style={{color: "red"}}>*</span></label>
                        <input type="text" className="form-control" id="city" placeholder={"Stadt"}
                               value={city} onChange={(e) => setCity(e.target.value)} required={true}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{"Region"}</label>
                        <input type="text" className="form-control" id="state" placeholder={"Region"}
                               value={state} onChange={(e) => setState(e.target.value)}/>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{"Land"} <span
                          style={{color: "red"}}>*</span></label>
                        <select className="form-control custom-select" id="country" value={country}
                                onChange={(e) => setCountry(e.target.value)} required={true}>
                          {countryList.map((entry => (
                            <option key={entry.id} value={entry.id}>{regionNames.of(entry.id)}</option>
                          )))}
                        </select>
                      </div>
                    </div>
                    <br/>
                    <button disabled={disableButton} type="submit" className="btn btn-block btn-lg btn-success">Weiter
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
