import {i18n, getLanguages} from "./i18n";
import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";

export default function LanguageSelector({doNotShowLanguageField}) {
  const [cookies, setCookie] = useCookies(['i18n_locale']);

  function initIntercom() {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "m68axokr",
      language_override: cookies.i18n_locale,
      hide_default_launcher: true,
    })
  }

  useEffect(() => {
    initIntercom();
  })

  return (
    <>
      <div className="right dropdown">
        <div>
          <Link to={"#"} onClick={() => {
            window.Intercom('show');
          }} className="headerButton text-info">
            <ion-icon name="help-circle-outline"/>
          </Link>
        </div>
        {!doNotShowLanguageField && (
          <div>
            <Link to="#" className="headerButton" data-bs-toggle="dropdown">
              <ion-icon name="globe-outline"/>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              {getLanguages().map(l => (
                <Link to={"#"} key={l.language} className="dropdown-item" onClick={(e) => {
                  i18n.changeLanguage(l.language);
                  setCookie('i18n_locale', l.language, {path: '/'})
                  window.Intercom('update');
                }}>{l.localizedName}</Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
