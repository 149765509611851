import React, {useEffect, useState} from "react";
import Header from "../../Layout/Header/Header";
import {useAuth} from "../../../../contexts/AuthContext";
import TransactionsList from "./TransactionsList";
import {Link, useNavigate} from "react-router-dom";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import {getCurrencySignByCode, getResponseData} from "../../../../functions/functions";
import Loader from "../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../contexts/ProjectContext";

export default function TransactionsPage()
{
  const {currentUser} = useAuth()
  const {project} = useProject()
  const [isLoading, setIsLoading] = useState(true)
  const [entries, setEntries] = useState([]);
  const [val, setVal] = useState();
  const [showNewIncomeButton, setShowNewIncomeButton] = useState(false)
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setVal(data.Values);
          setEntries(data.Transactions);
          setShowNewIncomeButton(data.ShowNewIncomeButton);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleClick(destination) {
    navigate(destination);
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("transactions")}/>
          <div id="appCapsule">
            <div className="section wallet-card-section pt-2">
              <div className="wallet-card">
                <div className="balance">
                  <div className="left">
                    <span className="title">{i18n.t("cash_balance")}</span>
                    {!isLoading && <h1 className="total">{val.CashBalance}  {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</h1>}
                  </div>
                  <div className="right">
                    <a href="#" className="button" data-bs-toggle="modal" data-bs-target="#DialogBlockButton">
                      <ion-icon name="add-outline"></ion-icon>
                    </a>
                  </div>
                </div>
                <div className="wallet-footer">
                  <div className="item">
                    <Link to={"./income"}>
                      <div className="icon-wrapper bg-success">
                        <ion-icon name="card-outline"></ion-icon>
                      </div>
                      <strong>{i18n.t("income")}</strong>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={"./expenses"}>
                      <div className="icon-wrapper bg-danger">
                        <ion-icon name="swap-vertical"></ion-icon>
                      </div>
                      <strong>{i18n.t("expenses")}</strong>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={"./customer"}>
                      <div className="icon-wrapper bg-info">
                        <ion-icon name="people-outline"></ion-icon>
                      </div>
                      <strong>{i18n.t("customers")}</strong>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={"./ownconsumption"}>
                      <div className="icon-wrapper bg-warning">
                        <ion-icon name="person-outline"/>
                      </div>
                      <strong>{i18n.t("ownconsumption.short")}</strong>
                    </Link>
                  </div>

                </div>

              </div>
            </div>

            <div className="section">
              <div className="row mt-2">
                <div className="col-6">
                  <div className="stat-box">
                    <div className="title">{i18n.t("income")}</div>
                    <div className="value text-success" style={{"fontSize" : "22px"}}>{val.IncomeBalance}  {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="stat-box">
                    <div className="title">{i18n.t("expenses")}</div>
                    <div className="value text-danger" style={{fontSize: "22px"}}>{val.ExpenseBalance}  {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</div>
                  </div>
                </div>
              </div>
            </div>

            <TransactionsList entries={entries} />
            <LegalFooter />
          </div>
        </>
      )}

      <div className="modal fade dialogbox" id="DialogBlockButton" data-bs-backdrop="static" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-footer">
              <div className="btn-list">
                {showNewIncomeButton && (
                  <>
                    <span onClick={() => handleClick("./income/new")} className="btn btn-text-primary btn-block" data-bs-dismiss="modal">{i18n.t("new_revenue_sale")}</span>
                    <span onClick={() => handleClick("./income/new?paymentStatus=reserved")} className="btn btn-text-secondary btn-block" data-bs-dismiss="modal">{i18n.t("new_reservation")}</span>
                  </>
                )}
                <span onClick={() => handleClick("./ownconsumption/new")} className="btn btn-text-warning btn-block" data-bs-dismiss="modal">{i18n.t("ownconsumption")}</span>
                <span onClick={() => handleClick("./expenses/new")} className="btn btn-text-danger btn-block" data-bs-dismiss="modal">{i18n.t("new_expense")}</span>
                <span onClick={() => handleClick("./customer/new")} className="btn btn-text-info btn-block" data-bs-dismiss="modal">{i18n.t("new_customer")}</span>
                <a href="#" className="btn btn-text-secondary btn-block" data-bs-dismiss="modal">{i18n.t("quit")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
