import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getCurrencySignByCode, getDateToday, getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import InfoTooltip from "../../../Layout/Tooltip/InfoTooltip";
import {useTranslation} from "react-i18next";
import {useProject} from "../../../../../contexts/ProjectContext";

export default function IncomeNew() {
  const navigate = useNavigate();
  const {ID} = useParams();
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const [customer, setCustomer] = useState();
  const [number, setNumber] = useState();
  const [price, setPrice] = useState();
  const [notice, setNotice] = useState("");
  const [isDeposit, setIsDeposit] = useState(false);
  const [date, setDate] = useState("");
  const [showDateField, setShowDateField] = useState(false);
  const [showPriceField, setShowPriceField] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const {t, i18n} = useTranslation();
  const {project} = useProject();

  /*TODO IncomeNew / Income Edit Datum bearbeiten
  *  Darauf achten, dass bei Reserviert trotzdem "reserviert für" steht*/

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/incomes/customerAndPaymentLists/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setPaymentStatus(data.PaymentStatus);
          setCustomer(data.Customer)
          setDate(getDateToday)
          setSelectedPaymentStatus("" + data.PaymentStatus[0].ID)
          if (searchParams.get("customerID")) {
            setSelectedCustomer(searchParams.get("customerID"));
          } else {
            setSelectedCustomer("" + data.Customer[0].ID)
          }
          if (searchParams.get("paymentStatus")) {
            if (searchParams.get("paymentStatus") === "reserved") {
              setSelectedPaymentStatus(4);
              setShowDateField(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      selectedCustomer: selectedCustomer,
      number: number,
      selectedPaymentStatus: selectedPaymentStatus,
      price: price,
      isDeposit: isDeposit,
      date: date,
      notice: notice,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/incomes/new/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  function handleIsDeposit(e) {
    setIsDeposit(e.target.checked)
    setShowDateField(false)
    setNumber(0);
    setSelectedPaymentStatus(5);
  }

  function handlePaymentStatus(e) {
    setShowPriceField(true)
    setSelectedPaymentStatus(e.target.value)
    if (e.target.value == 4) {
      setShowDateField(true);
    } else {
      setShowDateField(false);
      if (e.target.value == 3 || e.target.value == 2) {
        setShowPriceField(false)
      }
    }
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (
        <>
          <Header title={i18n.t("add_income")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="select4">{i18n.t("customer")}</label>
                        <select className="form-control custom-select" id="select4" value={selectedCustomer}
                                onChange={e => setSelectedCustomer(e.target.value)} required={true}>
                          {customer.map((entry => (
                            <option key={entry.ID} value={entry.ID}>{entry.Name}</option>
                          )))}
                        </select>
                      </div>
                    </div>
                    {!isDeposit && (
                      <>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="text4">{i18n.t("number")}</label>
                            <input type="number" className="form-control" id="text4"
                                   placeholder={i18n.t("number_example")} onChange={e => setNumber(e.target.value)}
                                   value={number || ''} required={true}/>
                          </div>
                        </div>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="select4">{i18n.t("payment_status")}</label>
                            <select className="form-control custom-select" id="select4" value={selectedPaymentStatus}
                                    onChange={handlePaymentStatus} required={true}>
                              {paymentStatus.map((entry => (
                                <option key={entry.ID} value={entry.ID}>{i18n.t(entry.translatableName)}</option>
                              )))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                    {showPriceField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("price_in")} {project.CurrencyCode === "" ? "€" : getCurrencySignByCode(project.CurrencyCode)}</label>
                          <input type="number" className="form-control" id="text4" placeholder={i18n.t("price_example")}
                                 onChange={e => setPrice(e.target.value)} value={price || ''} required={true}
                                 step="0.01"/>
                        </div>
                      </div>
                    )
                    }
                    {showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("reserved_for")}</label>
                          <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)}
                                 value={date} required={true}/>
                        </div>
                      </div>
                    )}
                    {!showDateField && (
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="text4">{i18n.t("date")}</label>
                          <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)}
                                 value={date} required={true}/>
                        </div>
                      </div>
                    )}
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="textarea4">{i18n.t("note")}</label>
                        <textarea id="expanses_note" rows="5" className="form-control"
                                  placeholder={i18n.t("note_example_income")} value={notice}
                                  onChange={e => setNotice(e.target.value)}/>
                        <i className="clear-input">
                          <ion-icon name="close-circle" role="img" className="md hydrated"
                                    aria-label="close circle"/>
                        </i>
                      </div>
                    </div>
                    <ul className="listview simple-listview transparent flush">
                      <li style={{padding: "0"}}>
                        <div>{i18n.t("deposit")}</div>
                        <InfoTooltip text={i18n.t("deposit_tooltip")} fontsize={"20px"} fontcolor={"#4A89DC"}/>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="SwitchCheckDefault3"
                                 defaultChecked={isDeposit} onChange={handleIsDeposit}/>
                          <label className="form-check-label" htmlFor="SwitchCheckDefault3"/>
                        </div>
                      </li>
                    </ul>

                    <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
