import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import {getFunctions, httpsCallable} from "firebase/functions";
import LanguageSelector from "../../../../i18n/LanguageSelector";
import {useTranslation} from "react-i18next";

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const navigate = useNavigate();
  const functions = getFunctions();
  const getGeneratePasswordResetLink = httpsCallable(functions, 'getGeneratePasswordResetLink');
  const { i18n } = useTranslation();

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setShowError(false);
      setLoading(true)

      await getGeneratePasswordResetLink(
        {
          "useremail": email
        });
      navigate("../login?action=passwordResetSend")
    } catch {
      setShowError(true);
    }

    setLoading(false)
  }

  return (
    <>
      <div className="appHeader no-border transparent position-absolute">
        <div className="left">
          <span onClick={() => navigate(-1)} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </span>
        </div>
        <div className="pageTitle"></div>
        <LanguageSelector />
      </div>
      <div id="appCapsule">

        <div className="section mt-2 text-center">
          <h1>{i18n.t("forgot_password")}</h1>
          <p>{i18n.t("enter_email_for_password_reset")}</p>
        </div>
        <div className="section mb-5 p-2">
          {showError && (
            <div className="alert alert-imaged alert-danger alert-dismissible fade show mb-3" role="alert">
              <div className="icon-wrap">
                <ion-icon name="key-outline" role="img" className="md hydrated" aria-label="card outline"></ion-icon>
              </div>
              <div>
                {i18n.t("password_could_not_be_reset")}
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body pb-1">

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">{i18n.t("email")}</label>
                    <input type="email" className="form-control" id="email1" placeholder={i18n.t("your_e-mail")} value={email}
                           onChange={(e) => setEmail((e.target.value))} required/>
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-button-group transparent">
              <button disabled={loading} type="submit" className="btn btn-primary btn-block btn-lg">{i18n.t("reset_password")}
              </button>
            </div>

          </form>
        </div>

      </div>
    </>
  )
}
