import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../../contexts/AuthContext";
import React, {useEffect, useState} from "react";
import {useProject} from "../../../../contexts/ProjectContext";
import LanguageSelector from "../../../../i18n/LanguageSelector";
import { useTranslation } from 'react-i18next';

export default function Welcome() {
  const {currentUser, logout} = useAuth();
  const {project} = useProject();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  function projectExists() {
    return typeof project !== 'undefined'
  }

  useEffect(() => {
    if (currentUser == null) {
      navigate("../login")
    } else {
      if (projectExists()) {
        navigate("/")
      } else {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleLogout() {
    await logout();
    navigate("/login");
  }

  return(
    <>
      {!isLoading && (
        <>
          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <span onClick={handleLogout} className="headerButton">
                {i18n.t("logout")}
              </span>
            </div>
            <div className="pageTitle"></div>
            <LanguageSelector doNotShowLanguageField={true} />
          </div>
          <div id="appCapsule">
            <div className="section">
              <div className="splash-page mt-5 mb-5">
                <div className="mb-3">
                  <img src="assets/img/icon/512x512.jpeg" alt={"Logo"} className="imaged w-50 " />
                </div>
                <h2 className="mb-2">{i18n.t("welcome")}</h2>
                <p>
                  {i18n.t("create_or_join")}
                </p>
              </div>
            </div>

            <div className="fixed-bar">
              <div className="row">
                <div className="col-6">
                  <Link to={"../join-project"} className="btn btn-lg btn-outline-secondary btn-block">{i18n.t("join_project")}</Link>
                </div>
                <div className="col-6">
                  <Link to={"../new-project"} className="btn btn-lg btn-primary btn-block">{i18n.t("new_project")}</Link>
                </div>
              </div>
            </div>

          </div>
        </>
      )}
    </>
  );
}