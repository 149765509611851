import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../../Layout/Header/Header";
import {useTranslation} from "react-i18next";

const Canceled = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header title={"Pro-Plan"} />
      <div id="appCapsule">
        <div className="section mt-2 mb-2">
          <div className="section-title large">Pro-Plan</div>
          <div className="card mt-1">
            <div className="card-body">
              <div className="section-title">{"Jetzt durchstarten mit Pollopi 🚀"}</div>
              <div className="section-title">{"Vorteile"}</div>
              <p>
                <span><ion-icon
                  style={{fontSize: "15px"}} name="checkmark-circle-outline"/> Krasse Funktion 1</span>
                <br/>
                <span><ion-icon
                  style={{fontSize: "15px"}} name="checkmark-circle-outline"/> Krasse Funktion 2</span>
              </p>
              <form action="https://payment.pollopi.com/create-checkout-session.php" method="POST">
                <input style={{display: "none"}}
                       id="quantity-input"
                       value="1"
                       name="quantity"
                       readOnly={true}
                />
                <button type="submit" className="btn btn-block btn-lg btn-success">Jetzt Kaufen</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Canceled;
