import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import TransactionsList from "../TransactionsList";
import OwnconsumptionList from "./OwnconsumptionList";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function OwnconsumptionPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [incomeList, setIncomeList] = useState();
  const [incomeTotal, setIncomeTotal] = useState();
  const [number, setNumber] = useState();
  const [customer, setCustomer] = useState();
  const {currentUser} = useAuth()
  const { ID } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      id: 12,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/ownconsumption/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setCustomer(data.Customer);
          setIncomeList(data.IncomeList);
          setNumber(data.Number);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleClick(destination) {
    navigate(destination);
  }

  function deleteEntry() {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/customers/delete`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        }
      )
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("ownconsumption")} showBackButton={true} dataBsTarget={"deleteItem"} />
          <div id="appCapsule">
            <div className="section wallet-card-section pt-2">
              <div className="wallet-card">
                <div className="balance mb-0">
                  <div className="left"><span className="title">{i18n.t("ownconsumption_this_month")}</span><h1 className="total text-success">{number} 🥚</h1></div>
                  <div className="right"><a href="#" className="button" data-bs-toggle="modal"
                                            data-bs-target="#DialogBlockButton">
                    <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon>
                  </a></div>
                </div>
              </div>
            </div>
            <OwnconsumptionList entries={incomeList} />
            <LegalFooter />
          </div>
        </>
      )}
      <div className="modal fade dialogbox" id="DialogBlockButton" data-bs-backdrop="static" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-footer">
              <div className="btn-list">
                <span onClick={() => handleClick("../transactions/ownconsumption/new")} className="btn btn-text-primary btn-block" data-bs-dismiss="modal">{i18n.t("add_entry")}</span>
                <a href="#" className="btn btn-text-secondary btn-block" data-bs-dismiss="modal">{i18n.t("quit")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
