import React, {useEffect, useState} from "react";
import {customizeDate, getResponseData} from "../../../../functions/functions";
import Header from "../../Layout/Header/Header";
import {useAuth} from "../../../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import Loader from "../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function EntryDetail() {
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { ID } = useParams();
  const [data, setData] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/stock/detail`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setData(data);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function deleteEntry() {
    const body = {
      id: ID,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/stock/delete`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        }
      )
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header deactivateChat={true} title={data.ChickenBreed} showBackButton={true} showDetailHeader={true} dataBsTarget={"deleteItem"} editNavigateTo={"../stock/entry/edit/" + data.ID} />
          <div id="appCapsule" className="full-height bg-white">
            <div className="section mt-2 mb-2">
              <div className="listed-detail mt-3">
                <div className="icon-wrapper">
                  <div className="iconbox">
                    <ion-icon name="egg"/>
                  </div>
                </div>
              </div>
              <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                  <strong>{i18n.t("stabling")}</strong>
                  <span>{customizeDate(data.Created, true)}</span>
                </li>
                <li>
                  <strong>{i18n.t("breed")}</strong>
                  <span>{data.ChickenBreed}</span>
                </li>
                {('NumberOfHens' in data) && (
                  data.NumberOfHens > 0 && (
                    <li>
                      <strong>{i18n.t("number_hens")}</strong>
                      <span>{data.NumberOfHens}</span>
                    </li>
                  )
                )}
                {('NumberOfRooster' in data) && (
                  data.NumberOfRooster > 0 && (
                    <li>
                      <strong>{i18n.t("number_roosters")}</strong>
                      <span>{data.NumberOfRooster}</span>
                    </li>
                  )
                )}
                {('RingColor' in data) && (
                  <li>
                    <strong>{i18n.t("ring_color")}</strong>
                    <span>{data.RingColor}</span>
                  </li>
                )}
                {('Notice' in data) && (
                  <li>
                    <strong>{i18n.t("note")}</strong>
                    <span style={{whiteSpace: "pre-line", lineHeight: "1.5"}}>{data.Notice}</span>
                  </li>
                )}
              </ul>


            </div>
            <LegalFooter />
          </div>
          <div className="modal fade dialogbox" id="deleteItem" data-bs-backdrop="static" tabIndex="-1" style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{i18n.t("delete_entry")}</h5>
                </div>
                <div className="modal-body mb-2">
                  {i18n.t("delete_entry_stock")}
                </div>
                <div className="modal-footer">
                  <div className="btn-inline">
                    <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal">{i18n.t("quit")}</a>
                    <span onClick={deleteEntry} className="btn btn-text-danger" data-bs-dismiss="modal">{i18n.t("delete")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
