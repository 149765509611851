import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../../contexts/AuthContext";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function PasswordEdit() {
  const navigate = useNavigate();
  const {currentUser, updateUserPassword} = useAuth()
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [checkPasswordHasUpperAndLowerCaseValue, setCheckPasswordHasUpperAndLowerCaseValue] = useState(false);
  const [checkPasswordMinimumEightCharactersValue, setCheckPasswordMinimumEightCharactersValue] = useState(false);
  const [checkPasswordContainsANumberValue, setCheckPasswordContainsANumberValue] = useState(false);
  const [checkIfPasswordMatchValue, setCheckIfPasswordMatchValue] = useState(false);
  const [passwordButtonDisabled, setPasswordButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    checkIfPasswordMatch();
  }, [password, passwordRepeat])

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/profile/getLanguageFields`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleUpdate() {
    const promises = []
    if (password !== passwordRepeat) {
      window.toastbox('toast', 2000, "Passwörter stimmt nicht überein.", "text-warning");
      return "";
    }
    if (password.length > 8) {
      promises.push(updateUserPassword(password))
    } else {
      window.toastbox('toast', 2000, "Passwort muss mindestens 8 Zeichen lang sein.", "text-warning");
      return "";
    }
    Promise.all(promises)
      .then(() => {
        window.toastbox('toast', 2000, "Passwort geändert.", "text-success");
        navigate("../settings")
      })
      .catch((e) => {
        window.toastbox('toast', 2000, "Bitte ausloggen und erneut anmelden.", "text-warning")
      })
  }

  function handlePasswordField(password) {
    setPassword(password);
    if (password.toLowerCase() !== password && password.toUpperCase() !== password) {
      setCheckPasswordHasUpperAndLowerCaseValue(true);
    } else {
      setCheckPasswordHasUpperAndLowerCaseValue(false);
    }
    if (password.length >= 8) {
      setCheckPasswordMinimumEightCharactersValue(true);
    } else {
      setCheckPasswordMinimumEightCharactersValue(false);
    }
    if (stringContainsNumber(password)) {
      setCheckPasswordContainsANumberValue(true);
    } else {
      setCheckPasswordContainsANumberValue(false);
    }
  }

  function checkIfPasswordMatch() {
    if (password !== passwordRepeat) {
      setCheckIfPasswordMatchValue(false);
      setPasswordButtonDisabled(true);
    } else {
      if (password.length > 0) {
        setCheckIfPasswordMatchValue(true);
        if (checkPasswordMinimumEightCharactersValue &&
          checkPasswordContainsANumberValue &&
          checkPasswordHasUpperAndLowerCaseValue) {
          setPasswordButtonDisabled(false);
        } else {
          setPasswordButtonDisabled(true);
        }
      }
    }
  }

  function stringContainsNumber(_input) {
    let string1 = String(_input);
    for (let i = 0; i < string1.length; i++) {
      if (!isNaN(string1.charAt(i)) && !(string1.charAt(i) === " ")) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Loader />
      {!isLoading && (
        <>
          <Header title={i18n.t("change_password")} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("new_password")}</label>
                      <input autoComplete="new-password" type="password" className="form-control" id="newPassword"
                             placeholder="" value={password} onChange={(e) => handlePasswordField(e.target.value)}/>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("repeat_password")}</label>
                      <input autoComplete="new-password" type="password" className="form-control" id="newPasswordRepeat"
                             placeholder="" value={passwordRepeat} onChange={(e) => {
                        setPasswordRepeat(e.target.value);
                      }}/>
                    </div>
                  </div>
                  {passwordButtonDisabled && <button disabled={passwordButtonDisabled} type="button" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>}
                  {!passwordButtonDisabled && <button type="button" className="btn btn-success mt-1 w-100" onClick={() => handleUpdate()}>{i18n.t("save")}</button>}
                </div>
              </div>
            </div>
            <div className="section mt-2">
              <div className="section-title">{i18n.t("password_security")}</div>
              <span className={checkPasswordMinimumEightCharactersValue ? "value text-success" : ""}><ion-icon
                style={{fontSize: "15px"}} name="checkmark-circle-outline"/> {i18n.t("eight_characters")}</span>
              <br/>
              <span className={checkPasswordHasUpperAndLowerCaseValue ? "value text-success" : ""}><ion-icon
                style={{fontSize: "15px"}} name="checkmark-circle-outline"/> {i18n.t("capital_lowercase")}</span>
              <br/>
              <span className={checkPasswordContainsANumberValue ? "value text-success" : ""}><ion-icon
                style={{fontSize: "15px"}} name="checkmark-circle-outline"/> {i18n.t("one_number")}</span>
              <br/>
              <span className={checkIfPasswordMatchValue ? "value text-success" : ""}><ion-icon style={{fontSize: "15px"}}
                                                                                                name="checkmark-circle-outline"/> {i18n.t("passwords_match")}</span>
            </div>
            <LegalFooter/>
          </div>
        </>
      )}
    </>
  );
}
