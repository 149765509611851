import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function CustomerList({title, list}) {
  const { t, i18n } = useTranslation();
  return (
    <div className="section mt-2">
      <div className="section-title">{title}</div>
      <div className="card">
        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
          {list.map((entry => (
            <li key={entry.Name}>
              <Link to={"./detail/" + entry.ID} className="item">
                <div className="in">
                  <div>
                    <strong>{entry.Name}</strong>
                  </div>
                </div>
              </Link>
            </li>
          )))}
        </ul>
      </div>
    </div>
  );
}
