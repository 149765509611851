import {Overlay, Tooltip} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function DashboardStatsBox({title, tooltip, value, color, alignment, id}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { t, i18n } = useTranslation();

  return(
    <>
      <div className="stat-box">
        <div className="title" style={{maxHeight: "18.195"}}>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td>
                {title}
              </td>
              <td style={{fontSize: "13px", color: "green", textAlign: "right"}} ref={target} onClick={() => setShow(!show)}>
                <ion-icon name="information-circle-outline"/>
                <Overlay target={target.current} show={show} placement={alignment}>
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      {tooltip}
                    </Tooltip>
                  )}
                </Overlay>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        {id > 0 && value < 1 ? (
          <>
            <Link to={"../statistics/edit/" + id} className="headerButton btn-text-success">
              {i18n.t("enter")} <span style={{fontSize: "18px", verticalAlign: "sub"}}><ion-icon name="add-circle-outline"/></span>
            </Link>
          </>
        ) : (
          <div className={`value ${color}`}>{value}</div>
        )}
      </div>
    </>
  );
}