import React, {useContext, useEffect, useState} from "react"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail
} from "firebase/auth";

import {auth} from "../config/firebase"
import {getResponseData} from "../functions/functions";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup(email, password, firstname, lastname, lng) {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    const body = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      uid: user.user.uid,
      lng: lng,
    }

    await fetch(`https://api.pollopi.com/signup/signup/`, getResponseData(body));
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
    return user.user
  }

  async function doSetCurrentUser(user) {
    setCurrentUser(user);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateUserEmail(email) {
    return updateEmail(auth.currentUser, email);
  }

  function updateUserPassword(password) {
    const user = auth.currentUser;
    updatePassword(user, password);
  }

  useEffect(() => {
    setLoading(true)
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword,
    doSetCurrentUser
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
