import React from "react";
import {useTranslation} from "react-i18next";

export default function ValueOverview({val}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="section mt-1">
        <div className="row mt-2">
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("today")}</div>
              <div className="value text-success">{val.Today}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="stat-box">
              <div className="title">{i18n.t("seven_days")}</div>
              <div className="value text-warning">{val.LastSevenDays}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
