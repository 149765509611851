import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import MemberList from "./MemberList";
import Loader from "../../../Layout/Loader/Loader";
import {useTranslation} from "react-i18next";

export default function MemberPage() {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [project, setProject] = useState([]);
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/settings/project/detail/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setProject(data.Project);
          setMemberList(data.Member);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleInviteMember() {
    const body = {
      uid: currentUser.uid,
      email: email,
    }

    fetch(`https://api.pollopi.com/settings/project/invitation/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          console.log(data.message);
          setEmail("");
        }
      )
  }

  return(
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("members")} showBackButton={true} showDetailHeader={true} />
          <div id="appCapsule">
            <div className="section wallet-card-section pt-2">
              <div className="wallet-card" style={{paddingTop: "0",paddingBottom: "0"}}>
                <div className="balance mb-0">
                  <div className="left"><span className="title">{i18n.t("project")}</span><h1 className="total text-success">{project.Title}</h1></div>
                  <div className="right"><a href="#" className="button" data-bs-toggle="modal"
                                            data-bs-target="#DialogForm">
                    <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon>
                  </a></div>
                </div>
              </div>
            </div>
            <div className="section mt-4 mb-4">
              <div className="section-heading">
                <h2 className="title">{i18n.t("members")}</h2>
              </div>
              <MemberList memberList={memberList} />
            </div>
            <LegalFooter />
          </div>
        </>
      )}
      <div className="modal fade dialogbox" id="DialogForm" data-bs-backdrop="static" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{i18n.t("invite_members")}</h5>
            </div>
            <form>
              <div className="modal-body text-start mb-2">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="text1">{i18n.t("mail_new_member")}</label>
                    <input type="text" className="form-control" id="text1" placeholder="info@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                  </div>
                </div>
                <span className={"pt-2"} style={{fontSize: "12px", color: "#000000", lineHeight: 1.5}}>
                    {i18n.t("maybe_spam")}
                  </span>
              </div>
              <div className="modal-footer">
                <div className="btn-inline">
                  <button type="button" className="btn btn-text-secondary"
                          data-bs-dismiss="modal">{i18n.t("quit")}
                  </button>
                  <button type="button" className="btn btn-text-primary"
                          data-bs-dismiss="modal" onClick={() => handleInviteMember()}>{i18n.t("invite")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
