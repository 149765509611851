import React, {useEffect, useState} from "react";
import Header from "../../../Layout/Header/Header";
import {useAuth} from "../../../../../contexts/AuthContext";
import ExpensesList from "./ExpensesList";
import LegalFooter from "../../../Layout/Footer/LegalFooter";
import {getResponseData} from "../../../../../functions/functions";
import Loader from "../../../Layout/Loader/Loader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ExpansesPage() {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true);
  const [today, setToday] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [lastMonthsList, setLastMonthsList] = useState([]);
  const [sliceValue, setSliceValue] = useState(2);
  const [showSliceButton, setShowSliceButton] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/transactions/expenses/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setToday(data.Today);
          setThisWeek(data.ThisWeek);
          setLastMonthsList(data.LastMonthsList);
          if (!(data.LastMonthsList.length > 0)) {
            setShowSliceButton(false);
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  function handleSliceValue() {
    if (sliceValue+1 >= lastMonthsList.length) {
      setShowSliceButton(false)
    }
    setSliceValue(sliceValue+1)
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Header title={i18n.t("expenses")} addExpense={true} showBackButton={true}/>
      {!isLoading && (
        <div id="appCapsule">
          {today.length > 0 ? (<ExpensesList title={i18n.t("today")} list={today}/>) : ""}
          {thisWeek.length > 0 ? (<ExpensesList title={i18n.t("this_week")} list={thisWeek}/>) : ""}
          <div className="mb-3">
          {lastMonthsList.length > 0 ? (
            lastMonthsList.slice(0,sliceValue).map((item) => (
              <>
                <ExpensesList key={item.month} title={i18n.t(item.month)} list={item.list}/>
              </>
            ))
          ) : (
            <div className="section mt-2">
              <p>{i18n.t("no_expenses_yet")}</p>
              <p><Link to={"../transactions/expenses/new"}>{i18n.t("add_expense_now")}</Link></p>
            </div>
          )}
          </div>
          {showSliceButton && (
            <div className="section mt-2 mb-3">
              <span onClick={handleSliceValue} className="btn btn-block btn-outline-secondary">{i18n.t("more")}</span>
            </div>
          )}
          <LegalFooter/>
        </div>
      )}
    </>
  );
}
