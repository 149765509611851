import React, {useEffect, useState} from 'react';
import Header from "../../Layout/Header/Header";
import Loader from "../../Layout/Loader/Loader";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Checkout() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setIsLoading(false)
  },[])



  return (
    <>
      <Loader isLoading={isLoading}/>
      {!isLoading && (

        <>
          <Header title={"Pro-Plan"} showBackButton={true}/>
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="section-title large">Pro-Plan</div>
              <div className="card mt-1">
                <div className="card-body">
                  <div className="section-title">{"Jetzt durchstarten mit Pollopi 🚀"}</div>
                  <div className="section-title">{"Vorteile"}</div>
                  <div>
                <span><ion-icon
                  style={{fontSize: "15px"}} name="checkmark-circle-outline"/> Krasse Funktion 1</span>
                    <br/>
                    <span><ion-icon
                      style={{fontSize: "15px"}} name="checkmark-circle-outline"/> Krasse Funktion 2</span>
                  </div>
                  <div className="section-title" style={{color: "#8CC152"}}>{"Jetzt einmalig kaufen für nur 10 €"}</div>
                  <button onClick={() => navigate("../payment/user")} type="submit" className="btn btn-block btn-lg btn-success">Jetzt Kaufen</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
