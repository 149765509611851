import React, {useEffect} from "react"
import Signup from "../Public/Pages/Signup/Signup"
import {AuthProvider} from "../../contexts/AuthContext"
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom"
import Login from "../Public/Pages/Login/Login"
import ForgotPassword from "../Public/Pages/ForgotPassword/ForgotPassword"
import PublicRoutes from "./PublicRoutes";
import StatisticsPage from "../Private/Pages/Statistics/StatisticsPage";
import StatisticsEdit from "../Private/Pages/Statistics/StatisticsEdit";
import Settings from "../Private/Pages/Settings/Settings";
import TransactionsPage from "../Private/Pages/Transactions/TransactionsPage";
import EmailEdit from "../Private/Pages/Settings/Profile/EmailEdit";
import ExpensesPage from "../Private/Pages/Transactions/Expenses/ExpensesPage";
import IncomePage from "../Private/Pages/Transactions/Income/IncomePage";
import IncomeDetail from "../Private/Pages/Transactions/Income/IncomeDetail";
import ExpenseDetail from "../Private/Pages/Transactions/Expenses/ExpenseDetail";
import CustomerPage from "../Private/Pages/Transactions/Customer/CustomerPage";
import IncomeNew from "../Private/Pages/Transactions/Income/IncomeNew";
import ExpenseNew from "../Private/Pages/Transactions/Expenses/ExpenseNew";
import IncomeEdit from "../Private/Pages/Transactions/Income/IncomeEdit";
import ExpenseEdit from "../Private/Pages/Transactions/Expenses/ExpenseEdit";
import CustomerNew from "../Private/Pages/Transactions/Customer/CustomerNew";
import Dashboard from "../Private/Pages/Dashboard/Dashboard";
import Notifications from "../Private/Pages/Notifications/Notifications";
import PrivateRoutes from "./PrivateRoutes";
import Layout from "../Private/Layout/Layout";
import CustomerDetail from "../Private/Pages/Transactions/Customer/CustomerDetail";
import MemberPage from "../Private/Pages/Settings/Project/MemberPage";
import ProjectEdit from "../Private/Pages/Settings/Project/ProjectEdit";
import {ProjectProvider} from "../../contexts/ProjectContext";
import LanguageEdit from "../Private/Pages/Settings/Profile/LanguageEdit";
import PasswordEdit from "../Private/Pages/Settings/Profile/PasswordEdit";
import Action from "./Action/Action";
import Welcome from "../Public/Pages/Signup/Welcome";
import JoinProject from "../Public/Pages/Signup/JoinProject";
import NewProject from "../Public/Pages/Signup/NewProject";
import StockPage from "../Private/Pages/Stock/StockPage";
import EntryNew from "../Private/Pages/Stock/EntryNew";
import {IntercomProvider} from "react-use-intercom";
import EntryDetail from "../Private/Pages/Stock/EntryDetail";
import EntryEdit from "../Private/Pages/Stock/EntryEdit";
import HelpPage from "../Private/Pages/Help/HelpPage";
import CollectionPage from "../Private/Pages/Help/CollectionPage";
import Checkout from "../Private/Pages/Payment/Checkout";
import Canceled from "../Private/Pages/Payment/Canceled";
import Success from "../Private/Pages/Payment/Success";
import UserData from "../Private/Pages/Payment/UserData";
import {useCookies} from "react-cookie";
import i18n from "../../i18n/i18n";
import OwnconsumptionPage from "../Private/Pages/Transactions/Ownconsumption/OwnconsumptionPage";
import OwnconsumptionNew from "../Private/Pages/Transactions/Ownconsumption/OwnconsumptionNew";
import OwnconsumptionDetail from "../Private/Pages/Transactions/Ownconsumption/OwnconsumptionDetail";
import OwnconsumptionEdit from "../Private/Pages/Transactions/Ownconsumption/OwnconsumptionEdit";
import CustomerEdit from "../Private/Pages/Transactions/Customer/CustomerEdit";

function App() {
  const INTERCOM_APP_ID = 'm68axokr';
  const [cookies, setCookie] = useCookies(['i18n_locale']);

  useEffect(() => {
    if (cookies.i18n_locale != null) {
      i18n.changeLanguage(cookies.i18n_locale)
    } else {
      setCookie('i18n_locale', window.navigator.language.substring(0,2), { path: '/' })
    }
  }, [cookies.i18n_locale])

  return (
    <>
      <Router>
        <AuthProvider>
          <ProjectProvider>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <Routes>
                <Route element={<PrivateRoutes/>}>
                  <Route element={<Layout/>}>
                    <Route exact path="/" element={<Dashboard/>}/>
                    <Route exact path="/statistics" element={<StatisticsPage/>}/>
                    <Route exact path="/statistics/edit/:ID" element={<StatisticsEdit/>}/>

                    <Route exact path="/stock" element={<StockPage/>}/>
                    <Route exact path="/stock/entry" element={<StockPage/>}/>
                    <Route exact path="/stock/entry/new" element={<EntryNew/>}/>
                    <Route exact path="/stock/entry/edit/:ID" element={<EntryEdit/>}/>
                    <Route exact path="/stock/entry/detail/:ID" element={<EntryDetail/>}/>

                    <Route exact path="/help" element={<HelpPage/>}/>
                    <Route exact path="/help/collection/:ID" element={<CollectionPage/>}/>

                    <Route exact path="/transactions" element={<TransactionsPage/>}/>
                    <Route exact path="/transactions/expenses" element={<ExpensesPage/>}/>
                    <Route exact path="/transactions/expenses/new" element={<ExpenseNew/>}/>
                    <Route exact path="/transactions/expenses/edit/:ID" element={<ExpenseEdit/>}/>
                    <Route exact path="/transactions/expenses/detail/:ID" element={<ExpenseDetail/>}/>
                    <Route exact path="/transactions/income" element={<IncomePage/>}/>
                    <Route exact path="/transactions/income/new" element={<IncomeNew/>}/>
                    <Route exact path="/transactions/income/edit/:ID" element={<IncomeEdit/>}/>
                    <Route exact path="/transactions/income/detail/:ID" element={<IncomeDetail/>}/>
                    <Route exact path="/transactions/customer" element={<CustomerPage/>}/>
                    <Route exact path="/transactions/customer/detail/:ID" element={<CustomerDetail/>}/>
                    <Route exact path="/transactions/customer/edit/:ID" element={<CustomerEdit/>}/>
                    <Route exact path="/transactions/customer/new" element={<CustomerNew/>}/>
                    <Route exact path="/transactions/ownconsumption" element={<OwnconsumptionPage/>}/>
                    <Route exact path="/transactions/ownconsumption/new" element={<OwnconsumptionNew/>}/>
                    <Route exact path="/transactions/ownconsumption/edit/:ID" element={<OwnconsumptionEdit/>}/>
                    <Route exact path="/transactions/ownconsumption/detail/:ID" element={<OwnconsumptionDetail/>}/>

                    <Route exact path="/notifications" element={<Notifications/>}/>
                    <Route exact path="/settings" element={<Settings/>}/>
                    <Route exact path="/settings/profile/update-email" element={<EmailEdit/>}/>
                    <Route exact path="/settings/profile/update-password" element={<PasswordEdit/>}/>
                    <Route exact path="/settings/project/member" element={<MemberPage/>}/>
                    <Route exact path="/settings/project/edit" element={<ProjectEdit/>}/>
                    <Route exact path="/settings/profile/language/edit" element={<LanguageEdit/>}/>

                    <Route exact path="/pro-plan" element={<Checkout/>}/>
                    <Route exact path="/payment/user" element={<UserData/>}/>
                    <Route exact path="/payment/success" element={<Success/>}/>
                    <Route exact path="/payment/canceled" element={<Canceled/>}/>

                  </Route>
                </Route>

                <Route element={<PublicRoutes/>}>
                  <Route path="/signup" element={<Signup/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/forgot-password" element={<ForgotPassword/>}/>
                </Route>

                <Route path="*" element={<Navigate to="/"/>}/>
                <Route path="/action" element={<Action />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/join-project" element={<JoinProject />} />
                <Route path="/new-project" element={<NewProject />} />
              </Routes>
            </IntercomProvider>
          </ProjectProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
