import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function LegalFooter() {
  const { t, i18n } = useTranslation();

  return(
    <>
      {/*<div className="appFooter mt-2" style={{position: "fixed",bottom: "55px",width: "100%"}}>
        <Link to={"../impressum"}>Impressum</Link> - <Link to={"../datenschutz"}>Datenschutzerklärung</Link>
      </div>*/}
    </>
  );
}
