import React, {useEffect, useState} from "react";
import Loader from "../../Layout/Loader/Loader";
import Header from "../../Layout/Header/Header";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import InfoTooltip from "../../Layout/Tooltip/InfoTooltip";
import {getResponseData} from "../../../../functions/functions";
import {useAuth} from "../../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function EntryNew() {
  const [isLoading, setIsLoading] = useState();
  const [date, setDate] = useState("");
  const [breed, setBreed] = useState("");
  const [notice, setNotice] = useState("");
  const [ringColor, setRingColor] = useState("");
  const [numberOfHens, setNumberOfHens] = useState(0);
  const [numberOfRoosters, setNumberOfRoosters] = useState(0);
  const [enterRoosters, setEnterRoosters] = useState(false);
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  function handleEnterRoosters(e) {
    setEnterRoosters(e.target.checked);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      date: date,
      breed: breed,
      notice: notice,
      ringColor: ringColor,
      numberOfHens: numberOfHens,
      numberOfRoosters: numberOfRoosters,
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/stock/new`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          navigate(-1);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }

  useEffect(() => {
    setIsLoading(false);
  },[])

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Header title={i18n.t("stabling")} showBackButton={true} />
          <div id="appCapsule">
            <div className="section mt-2 mb-2">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("breed")}</label>
                      <input type="text" className="form-control" id="text4" placeholder={i18n.t("breed_example")} onChange={e => setBreed(e.target.value)} value={breed  || ''} required/>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("number_hens")}</label>
                      <input type="number" className="form-control" id="text4" placeholder={i18n.t("number_hens_example")} onChange={e => setNumberOfHens(e.target.value)} value={numberOfHens  || ''}/>
                    </div>
                  </div>
                  {enterRoosters && (
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text4">{i18n.t("number_roosters")}</label>
                        <input type="number" className="form-control" id="text4" placeholder={i18n.t("number_roosters_example")} onChange={e => setNumberOfRoosters(e.target.value)} value={numberOfRoosters  || ''} />
                      </div>
                    </div>
                  )}

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("ring_color")}</label>
                      <input type="text" className="form-control" id="text4" placeholder={i18n.t("ring_color_example")} onChange={e => setRingColor(e.target.value)} value={ringColor  || ''} />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="text4">{i18n.t("stabling")}</label>
                      <input type="date" className="form-control" id="date" onChange={e => setDate(e.target.value)} value={date} />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="textarea4">{i18n.t("note")}</label>
                      <textarea id="expanses_note" rows="5" className="form-control"
                                placeholder={""} value={notice}
                                onChange={e => setNotice(e.target.value)}/>
                      <i className="clear-input">
                        <ion-icon name="close-circle" role="img" className="md hydrated"
                                  aria-label="close circle"/>
                      </i>
                    </div>
                  </div>
                  <ul className="listview simple-listview transparent flush">
                    <li style={{padding: "0"}}>
                      <div>{i18n.t("record_rooster")}</div>
                      <InfoTooltip text={i18n.t("roosters_tooltip")} fontsize={"20px"} fontcolor={"#4A89DC"} />
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="SwitchCheckDefault3" defaultChecked={enterRoosters} onChange={handleEnterRoosters} />
                        <label className="form-check-label" htmlFor="SwitchCheckDefault3"/>
                      </div>
                    </li>
                  </ul>
                  <button type="submit" className="btn btn-success mt-1 w-100">{i18n.t("save")}</button>
                  </form>
                </div>
              </div>
            </div>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}