import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import OwnconsumptionListEntry from "./OwnconsumptionListEntry";

export default function OwnconsumptionList({entries})
{
  const [sliceValue, setSliceValue] = useState(7);
  const [showButton, setShowButton] = useState(false);
  const [showShowAllButton, setShowShowAllButton] = useState(false);
  const [transactionsList, setTransactionsList] = useState(false);
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['showReserved']);

  useEffect(() => {
    setTransactionsList(entries.TransactionsList);
    if (entries.TransactionsList.length > 0) {
      if (entries.TransactionsList.length > sliceValue) {
        setShowButton(true)
      }
    }
  },[])

  function changeListViewShowAll() {
    setSliceValue(entries.length);
    setShowButton(false);
  }

  function changeListView() {
    if (sliceValue + 7 >= transactionsList.length) {
      console.log("ALGO")
      setShowButton(false);
    }
    setSliceValue(sliceValue+7);
  }

  return(
    <div className="section mt-4 mb-4">
      <div className="section-heading">
        <h2 className="title">{i18n.t("history")}</h2>
        <span onClick={changeListViewShowAll} className="link">{i18n.t("show_all")}</span>
      </div>
      <div className="transactions">
        {transactionsList && transactionsList.length > 0 ? (
          transactionsList.slice(0,sliceValue).map((entry => (
            <OwnconsumptionListEntry entry={entry} key={entry.ID} />
          )))
        ) : (
          i18n.t("no_entries_yet")
        )}
        {showButton && (<div className="pt-1">
          <span onClick={changeListView} className="btn btn-block btn-outline-secondary">{i18n.t("more")}</span>
        </div>)}
      </div>
    </div>
  );
}
