import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../contexts/AuthContext";
import {getResponseData} from "../../../../functions/functions";
import {useProject} from "../../../../contexts/ProjectContext";
import LanguageSelector from "../../../../i18n/LanguageSelector";
import {useTranslation} from "react-i18next";

export default function JoinProject() {
  const [invitationCode, setInvitationCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate()
  const {currentUser} = useAuth();
  const {triggerProjectReload, project} = useProject();
  const { i18n } = useTranslation();

  function projectExists() {
    return typeof project !== 'undefined'
  }

  useEffect(() => {
    if (currentUser == null) {
      navigate("../login")
    } else {
      if (projectExists()) {
        navigate("/")
      } else {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleJoinProject(e) {
    e.preventDefault();
    const body = {
      email: currentUser.email,
      invitationCode: invitationCode
    }

    fetch(`https://api.pollopi.com/settings/project/joinProject/`, getResponseData(body))
      .then(res => res.json())
      .then(
        async (result) => {
          const data = JSON.parse(result);
          if (data.message === "Error") {
            setError("Falscher Einladungscode")
          }
          if (data.message === "Done") {
            await triggerProjectReload();
            navigate("/")
          }
        }
      )
  }

  return (
    !isLoading && (
      <>
        <div className="appHeader no-border transparent position-absolute">
          <div className="left">
          <span onClick={() => navigate("../welcome")} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"/>
          </span>
          </div>
          <div className="pageTitle"/>
          <LanguageSelector doNotShowLanguageField={true} />
        </div>
        <div id="appCapsule">

          <div className="section mt-2 text-center">
            <h1>{i18n.t("invitation_code")}</h1>
            <h4>{i18n.t("enter_code")}</h4>
          </div>
          <div className="section mb-5 p-2">
            {error !== "" && (
              <div className="alert alert-imaged alert-danger alert-dismissible fade show mb-3" role="alert">
                <div className="icon-wrap">
                  <ion-icon name="key-outline" role="img" className="md hydrated" aria-label="card outline"/>
                </div>
                <div>
                  {error}
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"/>
              </div>
            )}
            <form onSubmit={handleJoinProject}>
              <div className="form-group basic">
                <input type="text" className="form-control verification-input" id="smscode" placeholder="•••••"
                       minLength="5" maxLength="5" value={invitationCode}
                       onChange={(e) => setInvitationCode(e.target.value)} required/>
              </div>
              <div className="form-button-group transparent">
                <button type="submit" className="btn btn-primary btn-block btn-lg">{i18n.t("join_project")}</button>
              </div>
            </form>
          </div>
          <div className="section mt-2 text-center">
            <h4>{i18n.t("code_in_e_mail")}</h4>
          </div>
        </div>
      </>
    )
  );
}