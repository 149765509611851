import Header from "../../Layout/Header/Header";
import LegalFooter from "../../Layout/Footer/LegalFooter";
import React, {useEffect, useState} from "react";
import Loader from "../../Layout/Loader/Loader";
import {getResponseData} from "../../../../functions/functions";
import {useAuth} from "../../../../contexts/AuthContext";
import {useTranslation} from "react-i18next";

export default function Notifications() {
  const [isLoading, setIsLoading] = useState(true);
  const [languageFields, setLanguageFields] = useState({});
  const {currentUser} = useAuth()
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const body = {
      uid: currentUser.uid,
    }

    fetch(`https://api.pollopi.com/notifications/`, getResponseData(body))
      .then(res => res.json())
      .then(
        (result) => {
          const data = JSON.parse(result);
          setLanguageFields(data.Language)
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(true);
        }
      )
  }, [])

  return (
    <>
      <Loader />
      {!isLoading && (
        <>
          <Header title={languageFields.Title} showBackButton={true} doNotShowNotificationsButton={true} />
          <div id="appCapsule">
            <div className="section full">
              <div className="section mt-2">
                {languageFields.NoNotifications}
              </div>
            </div>
            <LegalFooter />
          </div>
        </>
      )}
    </>
  );
}
