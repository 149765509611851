import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function Loader({isLoading}) {
  const [show, setShow] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let showTimeOut = true;
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
    }, 300)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  return(
    <>
      {(show || isLoading) && (
        <div id="loader">
          <img src="../../../assets/img/loading-icon.png" alt="icon" className="loading-icon" />
        </div>
      )}
    </>
  );
}